var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (slide, index) {
    var _slide$meta;
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "main-visual__inner",
      attrs: {
        "to": slide !== null && slide !== void 0 && slide.isOuterLink ? null : slide === null || slide === void 0 ? void 0 : slide.url,
        "href": slide !== null && slide !== void 0 && slide.isOuterLink ? slide === null || slide === void 0 ? void 0 : slide.url : null,
        "target": slide === null || slide === void 0 ? void 0 : slide.target,
        "elevation": "0"
      }
    }, [_c('div', {
      staticClass: "main-visual__img",
      style: 'background-image:url(' + (slide === null || slide === void 0 ? void 0 : slide.image) + ');'
    }, [_c('span', {
      staticClass: "d-sr-only"
    }, [_vm._v(_vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta = slide.meta) === null || _slide$meta === void 0 ? void 0 : _slide$meta.title))])])])], 1);
  }), 0), _vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right"
  })])]);

}]

export { render, staticRenderFns }