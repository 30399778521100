var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "board-list board-list--text"
  }, [!_vm.items.length ? _c('li', [_c('div', {
    staticClass: "board-list--text__tit"
  }, [_vm._v("공지사항이 없습니다.")])]) : _vm._e(), _vm._l(_vm.items, function (_ref) {
    var _id = _ref._id,
      subject = _ref.subject,
      createdAt = _ref.createdAt;
    return _c('li', {
      key: _id
    }, [_c('router-link', {
      attrs: {
        "to": `/center/notice/${_id}`
      }
    }, [_c('div', {
      staticClass: "board-list--text__tit"
    }, [_vm._v(_vm._s(subject))]), _c('div', {
      staticClass: "board-list--text__date"
    }, [_vm._v(_vm._s(createdAt.toDate()))])])], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }