<template>
    <div class="review-list-best" v-show="!loading && !!reviews">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <!-- <div class="swiper-slide" v-for="review in reviews" :key="review._id" :style="style"> -->
                <div class="swiper-slide" v-for="(review, index) in reviews" :key="review._id">
                    <review-item v-bind="{ review, index: index + 1, isBiggerImage }" isBest />
                </div>
            </div>
        </div>
        <div class="swiper-control">
            <div class="swiper-prev"><i class="icon icon-chevron-left-white icon-chevron--lg"></i></div>
            <!-- <div class="swiper-pagination"></div> -->
            <div class="swiper-next"><i class="icon icon-chevron-right-white icon-chevron--lg"></i></div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import ReviewItem from "./review-item.vue";

export default {
    components: {
        ReviewItem,
    },
    props: {
        isMain: { type: Boolean, default: false },
    },
    data: () => ({
        loading: false,
        reviews: null,
        swiper: undefined,
    }),
    computed: {
        cols() {
            return this.isMain ? 4 : +(this.$route.query.cols || 6);
        },
        isBiggerImage() {
            return this.cols == 12;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        // style() {
        //     const percentage = (this.cols / 12).toFixed(2);
        //     console.log({percentage});
        //     return `width:calc(var(--container) * ${percentage} + ${(20 * percentage).toFixed(0)}px);`;
        // },
    },
    mounted() {
        this.search();
    },
    watch: {
        cols() {
            this.destroySwiper().then(this.search);
        },
        isLoggedIn() {
            this.destroySwiper().then(this.search);
        },
    },
    methods: {
        async destroySwiper() {
            this.swiper.destroy(true, true);
        },
        async search() {
            this.loading = true;
            const { reviews } = await api.v1.eyes.reviews.gets({
                params: { isBest: true, sort: "meta.likes" },
            });
            // this.reviews = reviews.sort(() => Math.round(Math.random() * 2) - 1);
            this.reviews = reviews;
            this.loading = false;

            this.$nextTick(() => {
                var options = {};
                if (this.cols == 12) {
                    options = {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: true,
                        navigation: {
                            nextEl: ".review-list-best .swiper-next",
                            prevEl: ".review-list-best .swiper-prev",
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 36,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 36,
                            },
                        },
                    };
                } else if (this.cols == 6) {
                    options = {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: true,
                        navigation: {
                            nextEl: ".review-list-best .swiper-next",
                            prevEl: ".review-list-best .swiper-prev",
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 36,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 36,
                            },
                        },
                    };
                } else {
                    options = {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: true,
                        navigation: {
                            nextEl: ".review-list-best .swiper-next",
                            prevEl: ".review-list-best .swiper-prev",
                        },
                        lazy: {
                            loadPrevNext: true,
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 36,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 36,
                            },
                        },
                    };
                }
                this.swiper = new Swiper(".review-list-best .swiper-container", options);
            });
        },
    },
};
</script>

<style scoped>
.review-list-best {
    position: relative;
    margin: -36px -20px;
}
.review-list-best .swiper-container {
    padding: 36px 20px;
}
.review-list-best .swiper-prev,
.review-list-best .swiper-next {
    display: none;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: none;
}
.review-list-best .swiper-prev .icon,
.review-list-best .swiper-next .icon {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
}
.review-list-best .v-card.elevation-6 {
    box-shadow: 0 0 20px var(--shadow) !important;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .review-list-best .swiper-prev,
    .review-list-best .swiper-next {
        display: flex;
        width: 60px;
        height: 60px;
    }
    .review-list-best .swiper-prev {
        left: -30px;
    }
    .review-list-best .swiper-next {
        right: -30px;
    }
}
@media (min-width: 1200px) {
}
</style>
