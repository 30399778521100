import files from "./files";
import locations from "./locations";
import reviews from "./reviews";
import tags from "./tags";
export default {
    files,
    locations,
    reviews,
    tags,
};
