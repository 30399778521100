<template>
    <div v-show="message" class="notification-item-message">
        <template v-if="actor">
            <strong>{{ actor.nickname }}</strong>
            님이
        </template>
        <span v-html="message" />
    </div>
</template>

<script>
import { NOTIFICATION_TYPES } from "@/assets/variables";
import dayjs from "dayjs";
const { reviewComment } = NOTIFICATION_TYPES;

export default {
    props: ["notification"],
    computed: {
        actor() {
            const { type, notifier, notifiee } = this.notification || {};

            let type1 = [reviewComment.value],
                type2 = [];

            if (type1.includes(type)) return notifier;
            if (type2.includes(type)) return notifiee;
            return;
        },
        message() {
            const { type } = this.notification || {};

            if ([reviewComment.value].includes(type)) return "댓글을 작성했습니다.";

            return;
        },
    },
};
</script>

<style scoped>
.notification-item-message{
    font-size: 1.4rem;
    color:#777;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
</style>
