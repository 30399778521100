var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isMap ? _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_vm._t("default")], 2)]) : _vm._e(), _vm._t("map"), _vm._t("drawer"), !_vm.hideFooter ? _c('main-footer') : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }