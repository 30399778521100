<template>
    <v-card v-if="type == 'services'" :to="{ path: '/reviews', query: { keyword: `＃${item.text}` } }" v-bind="cardAttrs">
        <v-row no-gutters align="center" class="px-10 fill-height">
            <v-col cols="auto">
                <v-avatar size="32" class="elevation-8">
                    <v-icon small>mdi-eye</v-icon>
                </v-avatar>
            </v-col>
            <v-col style="width:calc(100% - 40px)">
                <v-card-title class="font-size-16"> ＃ {{ item.text }} </v-card-title>
                <v-card-subtitle class="font-size-14">진료과목</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
    <v-card v-else-if="type == 'areas'" :to="{ path: `/locations`, query: { sido, gugun } }" v-bind="cardAttrs">
        <v-row no-gutters align="center" class="px-10 fill-height">
            <v-col cols="auto">
                <v-avatar size="32" class="elevation-8">
                    <v-icon small>mdi-map</v-icon>
                </v-avatar>
            </v-col>
            <v-col style="width:calc(100% - 40px)">
                <v-card-title class="font-size-16"> ＃ {{ item.text }} 안과 </v-card-title>
            </v-col>
        </v-row>
    </v-card>
    <v-card v-else-if="type == 'locations'" :to="{ path: `/locations/${item.value}` }" v-bind="cardAttrs">
        <v-row no-gutters align="center" class="px-10 fill-height">
            <v-col cols="auto">
                <v-avatar size="32" class="elevation-8">
                    <v-icon small>mdi-hospital-box</v-icon>
                </v-avatar>
            </v-col>
            <v-col style="width:calc(100% - 40px)">
                <v-card-title class="font-size-16"> {{ item.text }} </v-card-title>
                <v-card-subtitle class="font-size-14">안과 / {{ item.address }}</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
    <v-card v-else-if="type == 'tags'" :to="{ path: '/reviews', query: { _tags: item.value } }" v-bind="cardAttrs">
        <v-row no-gutters align="center" class="px-10 fill-height">
            <v-col cols="auto">
                <v-avatar size="32" class="elevation-8">
                    <v-icon small>mdi-pound</v-icon>
                </v-avatar>
            </v-col>
            <v-col style="width:calc(100% - 40px)">
                <v-card-title class="font-size-16">
                    <span class="text-truncate"> {{ item.text }}</span>
                </v-card-title>
                <v-card-subtitle class="font-size-14">안과후기</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
const cardAttrs = {
    class: "mx-n12 px-12 py-0",
    width: "100%",
    flat: true,
    tile: true,
};
export default {
    props: {
        item: { type: Object, default: () => ({}) },
    },
    data: () => ({
        cardAttrs,
    }),
    computed: {
        type() {
            return this.item.type;
        },
        sido() {
            return this.item.sido || this.item.value;
        },
        gugun() {
            if (this.sido == this.item.value) return;
            else return this.item.value;
        },
    },
};
</script>

<style></style>
