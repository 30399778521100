<template>
    <v-tabs-items v-model="tab" class="notification-list">
        <v-tab-item>
            <p class="notification-list__txt">알림을 로드하고 있습니다.</p>
        </v-tab-item>
        <v-tab-item>
            <template v-if="unreads.length">
                <notification-item v-for="(notification, i) in unreads" :key="i" :notification="notification" @close="close" @search="search" />
            </template>
            <p v-else class="notification-list__txt">미확인 알림이 없습니다.</p>
        </v-tab-item>
        <v-tab-item>
            <template v-if="notifications.length">
                <notification-item v-for="(notification, i) in notifications" :key="i" v-bind="{ notification }" @close="close" @search="search" />
            </template>
            <p v-else class="notification-list__txt">알림이 없습니다.</p>
        </v-tab-item>
    </v-tabs-items>
</template>

<script>
import NotificationItem from "./notification-item.vue";
export default {
    components: { NotificationItem },
    props: ["value", "tab", "notifications", "unreads"],
    methods: {
        close() {
            this.$emit("input", false);
        },
        search() {
            this.$emit("search");
        },
    },
};
</script>

<style lang="scss" scoped>
.notification-list {
    max-height: calc(100vh - (var(--header-body) + 66px));
    overflow-y: auto;
}
.notification-list__txt{
    font-size: 1.6rem;
    color:#333;
    text-align: center;
    padding:20px 12px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
