<template>
    <section class="main-section">
        <v-container>
            <slot />
        </v-container>
    </section>
</template>

<script>
export default {
    props: {
        title: String
    },
    data(){
        return{
        }
    },
};
</script>

<style scoped>
</style>