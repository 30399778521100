var render = function render(){
  var _vm$review, _vm$review$writer, _vm$review2, _vm$review3, _vm$review3$location, _vm$review4, _vm$review5, _vm$review6, _vm$review6$location, _vm$review6$location$, _vm$review6$location$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    class: {
      'v-card--default': !_vm.isLocationView
    },
    attrs: {
      "rounded": "xl",
      "outlined": !_vm.isBest,
      "elevation": _vm.isBest ? 6 : 0
    }
  }, 'v-card', {
    to: _vm.to
  }, false), [_c('v-card-text', {
    staticClass: "d-flex flex-column px-16 py-20 px-md-26 py-md-36"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": _vm.isBiggerImage ? 10 : 'auto'
    }
  }, [_c('div', {
    staticClass: "mb-8 mb-md-18"
  }, [_c('review-item-chips', _vm._b({}, 'review-item-chips', {
    review: _vm.review,
    index: _vm.index
  }, false))], 1), _c('div', {
    staticClass: "mb-6 mb-md-12"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 primary--text"
  }, [_vm._v(_vm._s(_vm.serviceName))]), _c('span', {
    staticClass: "font-size-12 font-size-md-14 grey-a--text ml-6"
  }, [_vm._v(_vm._s((_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$writer = _vm$review.writer) === null || _vm$review$writer === void 0 ? void 0 : _vm$review$writer.nickname) + "님")])])]), _c('review-item-texts', _vm._b({
    staticClass: "v-card--default__content",
    on: {
      "showsDetail": function (bool) {
        return _vm.showsDetail = bool;
      }
    }
  }, 'review-item-texts', {
    review: _vm.review,
    opened: _vm.opened,
    showsDetail: _vm.showsDetail,
    isBest: _vm.isBest,
    isLink: _vm.isLink,
    isBiggerImage: _vm.isBiggerImage,
    isLoggedIn: _vm.isLoggedIn
  }, false))], 1), _vm.isBiggerImage && !_vm.showsDetail ? _c('v-col', {
    attrs: {
      "cols": _vm.isBiggerImage ? 2 : 'auto'
    }
  }, [_c('div', {
    staticClass: "pl-10 pl-16"
  }, [_c('imgs-dialog', {
    attrs: {
      "imgs": _vm.images,
      "disabled": _vm.isBest || _vm.isLink || !_vm.isLoggedIn
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$images, _vm$images2;
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "rounded w-100px w-sm-180px",
          staticStyle: {
            "overflow": "hidden",
            "cursor": "pointer"
          },
          attrs: {
            "src": (_vm$images = _vm.images) === null || _vm$images === void 0 ? void 0 : _vm$images[0],
            "aspect-ratio": 1 / 1,
            "content-class": "d-flex justify-end align-end"
          }
        }, 'v-img', attrs, false), on), [_c('v-overlay', {
          attrs: {
            "slot": "placeholder",
            "absolute": "",
            "z-index": "0",
            "color": "grey lighten-2"
          },
          slot: "placeholder"
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "grey"
          }
        }, [_vm._v("mdi-image-off-outline")])], 1), _c('v-card', {
          staticClass: "d-flex align-center justify-center",
          staticStyle: {
            "border-radius": "12px 0px !important"
          },
          attrs: {
            "width": "36",
            "height": "36",
            "tile": "",
            "dark": "",
            "color": "rgba(0,0,0,0.5)"
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-light"
        }, [_vm._v(_vm._s((_vm$images2 = _vm.images) === null || _vm$images2 === void 0 ? void 0 : _vm$images2.length))])])], 1)];
      }
    }], null, false, 1434133142)
  })], 1)]) : _vm._e()], 1), _c('div', {
    class: {
      blurred: !_vm.isLoggedIn
    }
  }, [_c('review-item-stars', _vm._b({}, 'review-item-stars', {
    review: _vm.review,
    opened: _vm.opened,
    showsDetail: _vm.showsDetail
  }, false)), _c('review-item-doctor', _vm._b({}, 'review-item-doctor', {
    review: _vm.review,
    opened: _vm.opened,
    showsDetail: _vm.showsDetail
  }, false)), _c('review-item-charges', _vm._b({}, 'review-item-charges', {
    review: _vm.review,
    opened: _vm.opened,
    showsDetail: _vm.showsDetail
  }, false))], 1), !_vm.isLocationView ? _c('div', {
    staticClass: "pt-8 pt-md-14 mt-auto mb-n4"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [!!((_vm$review2 = _vm.review) !== null && _vm$review2 !== void 0 && _vm$review2.location) ? _c('div', {
    staticClass: "font-size-14 grey-a--text"
  }, [_vm._v(_vm._s((_vm$review3 = _vm.review) === null || _vm$review3 === void 0 ? void 0 : (_vm$review3$location = _vm$review3.location) === null || _vm$review3$location === void 0 ? void 0 : _vm$review3$location.areaName))]) : _vm._e(), !!((_vm$review4 = _vm.review) !== null && _vm$review4 !== void 0 && _vm$review4.location) ? _c('router-link', {
    staticClass: "d-flex align-center",
    attrs: {
      "to": `/locations/${_vm.review._location}`
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-16 font-weight-bold grey-2--text"
  }, [_vm._v(_vm._s((_vm$review5 = _vm.review) === null || _vm$review5 === void 0 ? void 0 : _vm$review5.locationName))]), _c('v-icon', {
    staticClass: "font-size-md-18 grey-2--text mt-n2 ml-4"
  }, [_vm._v("mdi-chevron-right")])], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "value": (((_vm$review6 = _vm.review) === null || _vm$review6 === void 0 ? void 0 : (_vm$review6$location = _vm$review6.location) === null || _vm$review6$location === void 0 ? void 0 : (_vm$review6$location$ = _vm$review6$location.meta) === null || _vm$review6$location$ === void 0 ? void 0 : (_vm$review6$location$2 = _vm$review6$location$.starsAverage) === null || _vm$review6$location$2 === void 0 ? void 0 : _vm$review6$location$2.total) || 0) / 10,
      "readonly": "",
      "half-increments": "",
      "color": "warning",
      "background-color": "warning",
      "dense": ""
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.review ? _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsDetail,
      expression: "showsDetail"
    }],
    staticClass: "mt-20 mt-md-30"
  }, [_c('review-item-comments', _vm._b({
    on: {
      "syncComments": function (num) {
        return _vm.commentsLength = num;
      }
    }
  }, 'review-item-comments', {
    review: _vm.review,
    _review: _vm._review
  }, false))], 1)]) : _vm._e()], 1), _c('v-card-actions', {
    staticClass: "pa-16 px-md-26 primary white--text"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('review-like-button', _vm._b({}, 'review-like-button', {
    review: _vm.review
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-comment-outline"
  }), _c('span', {
    staticClass: "font-size-14 ml-4"
  }, [_vm._v(_vm._s(_vm.commentsLength))])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }