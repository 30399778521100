<template>
    <v-dialog v-model="shows" v-bind="$attrs">
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card rounded="lg">
            <v-card-title class="white--text justify-center pa-10" style="height:50px; background-color:#1f232f;">
                <span class="font-size-14 font-size-md-16">{{ title }}</span>
                <v-btn @click="shows = false" absolute right text class=" min-w-auto px-4 py-0">
                    <v-icon color="white" class="font-size-20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <div>
                <slot />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        title: { type: String, default: null },
    },
    data: () => ({
        shows: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.shows = this.value;
        },
        emit() {
            this.$emit("input", this.shows);
        },
    },
};
</script>

<style></style>
