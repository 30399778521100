<template>
    <div class="table-style__row">
        <div class="table-style__th">{{ label }} <span v-show="pointer" style="color: rgb(255, 0, 0);">*</span></div>
        <div class="table-style__td">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        label: String,
        pointer: Boolean,
    },
};
</script>
