<template>
    <div @click="show" :class="className" class="notification-item">
        <div class="notification-item__inner">
            <v-row no-gutters :class="className" @click="show">
                <v-col cols="auto" class="d-flex align-center">
                    <notification-item-avatar v-bind="{ notification }" />
                </v-col>
                <v-col class="notification-item__con">
                    <div>
                        <notification-item-title v-bind="{ notification }" />
                        <notification-item-subtitle v-bind="{ notification }" />
                        <notification-item-message v-bind="{ notification }" />
                        <notification-item-comment v-bind="{ notification }" />
                        <notification-item-timestamp v-bind="{ notification }" />
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-btn text color="red" small class="v-btn--none-active-bg h-auto min-w-auto py-0 px-4" @click.stop.capture="remove">삭제</v-btn>
                </v-col>
            </v-row>
        </div>
        <v-divider class="notification-item-divider"/>
    </div>
</template>

<script>
import NotificationItemAvatar from "./notification-item-avatar.vue";
import NotificationItemTitle from "./notification-item-title.vue";
import NotificationItemSubtitle from "./notification-item-subtitle.vue";
import NotificationItemMessage from "./notification-item-message.vue";
import NotificationItemComment from "./notification-item-comment.vue";
import NotificationItemTimestamp from "./notification-item-timestamp.vue";

import api from "@/api";
import { NOTIFICATION_TYPES } from "@/assets/variables";

export default {
    components: {
        NotificationItemAvatar,
        NotificationItemTitle,
        NotificationItemSubtitle,
        NotificationItemMessage,
        NotificationItemComment,
        NotificationItemTimestamp,
    },
    props: ["notification", "value"],
    computed: {
        className() {
            const { isRead } = this.notification || {};
            if (isRead) return "notification-item-content";
            else return "notification-item-content notification-item_unread";
        },
    },
    methods: {
        show() {
            const { type, _review, _comment } = this.notification || {};

            if (type == NOTIFICATION_TYPES.reviewComment.value) {
                this.$router.push(`/reviews/${_review}/comments/${_comment}`);
            }

            setTimeout(this.setReceived, 1000);
        },

        async setReceived() {
            try {
                const { _id } = this.notification;
                if (_id) {
                    await api.v1.eyes.notifications.put({ _id, receivedAt: Date.now() });
                    this.$emit("close");
                    this.$emit("search");
                }
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove() {
            try {
                const { _id } = this.notification;
                if (_id) {
                    await api.v1.eyes.notifications.delete({ _id });
                    this.$emit("search");
                }
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style scoped>
.notification-item__inner{
    padding: 12px;
}
.notification-item:last-child >.notification-item-divider{
    display: none;
}
.notification-item__con{
    width: calc(100% - 48px - 34px);
}
.notification-item__con >div{
    padding:0 12px;
}
.notification-item-content{
    cursor: pointer;
}
.notification-item-content.notification-item_unread{
    background-color: #e1f5fe;
}
</style>
