<template>
    <v-expand-transition v-if="review?.doctor">
        <div v-show="opened || showsDetail" class="mt-16 mt-md-20">
            <div class="mb-4">
                <b class="font-size-14 grey-2--text">진료내역 및 가격</b>
            </div>

            <v-chip-group column>
                <v-chip v-for="item in review?.services || []" :key="item" outlined small>{{ SERVICES[item].text }}</v-chip>
            </v-chip-group>

            <v-row class="my-4 mx-n4">
                <template v-for="(img, index) in imgs">
                    <v-col cols="3" md="2" lg="1" :key="img" class="pa-4">
                        <imgs-dialog title="영수증 이미지" v-bind="{ imgs }" :disabled="!isLoggedIn">
                            <template #activator="{ attrs, on, setIndex }">
                                <v-img v-bind="attrs" v-on="on" :src="img" :aspect-ratio="1 / 1" class="v-sheet--outlined rounded" @click="setIndex(index)" />
                            </template>
                        </imgs-dialog>
                    </v-col>
                </template>
            </v-row>

            <div class="mt-16">
                <v-card flat class="grey-f7">
                    <v-row no-gutters class="px-16 py-12 font-size-14">
                        <v-col cols="auto">총 금액</v-col>
                        <v-spacer />
                        <v-col cols="auto">{{ review?.charges?.total?.format?.() || 0 }} 원</v-col>
                    </v-row>
                </v-card>
            </div>
        </div>
    </v-expand-transition>
</template>

<script>
import { SERVICES } from "@/assets/variables";

import ImgsDialog from "@/components/dumb/imgs-dialog.vue";

export default {
    components: {
        ImgsDialog,
    },
    props: {
        review: { type: Object, default: () => ({}) },
        opened: { type: Boolean, default: false },
        showsDetail: { type: Boolean, default: false },
    },
    data: () => ({
        SERVICES,
    }),
    computed: {
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        imgs() {
            return this.review?.receiptImgs?.map?.(({ url }) => url);
        },
    },
};
</script>

<style lang="scss" scoped></style>
