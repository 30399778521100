<template>
    <li :class="{ 'light-blue lighten-5': isRouted }">
        <div :class="{ 'mx-n16 mx-md-n26 px-36 px-md-50 py-16 py-md-20': true }" ref="comment">
            <div class="mb-8">
                <strong class="font-size-16 font-weight-bold grey-2--text">{{ value?.user?.nickname }}</strong>
            </div>
            <div>
                <comment-input v-if="isEdit" v-model="value" usesCustomActivator buttonText="수정" @save="(item) => ($emit('save', item) || true) && (isEdit = !isEdit)" />
                <template v-else>
                    <div class="font-size-14">
                        <span v-if="value?.mention" class="primary--text mr-10">{{ value.mention }}</span>
                        <span class="grey-7--text">{{ value?.content }}</span>
                    </div>
                </template>
            </div>

            <div class="mt-8">
                <v-row no-gutters align="center" class="font-size-14 grey-a--text">
                    <v-col cols="auto">{{ value?.createdAt?.toAgo?.() }}</v-col>
                    <template v-if="isLoggedIn">
                        <v-col cols="auto"><span class="mx-8">·</span></v-col>
                        <v-col cols="auto">
                            <v-btn @click="$emit('mention', value)" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">답글달기</v-btn>
                        </v-col>
                    </template>
                    <template v-if="isUser">
                        <v-col cols="auto"><span class="mx-8">·</span></v-col>
                        <v-col cols="auto">
                            <v-btn @click="isEdit = !isEdit" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">수정</v-btn>
                        </v-col>
                        <v-col cols="auto"><span class="mx-8">·</span></v-col>
                        <v-col cols="auto">
                            <v-btn @click="$emit('remove', value)" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">삭제</v-btn>
                        </v-col>
                    </template>
                </v-row>
            </div>
        </div>
        <v-divider />
    </li>
</template>

<script>
import CommentInput from "./comment-input.vue";
export default {
    components: {
        CommentInput,
    },
    props: {
        value: {
            user: { type: Object, default: () => ({}) },
            content: { type: String, default: "" },
        },
    },
    data: () => ({
        isEdit: false,
    }),
    computed: {
        isUser() {
            return this.value?.user?._id == this.$store?.state?.payload?._user;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        isRouted() {
            return this.$route.params._comment == this.value?._id;
        },
    },
    mounted() {
        if (this.isRouted) this.scrollToThis();
    },
    watch: {
        isRouted(value) {
            if (value) this.scrollToThis();
        },
    },
    methods: {
        scrollToThis() {
            this.$nextTick(() => setTimeout(() => window.scrollTo({ top: this.$refs["comment"]?.getBoundingClientRect?.()?.top - 96, behavior: "smooth" }), 500));
        },
    },
};
</script>

<style></style>
