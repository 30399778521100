var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    attrs: {
      "search-input": _vm.text,
      "hide-no-data": "",
      "hide-details": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.text = $event;
      },
      "update:search-input": function ($event) {
        _vm.text = $event;
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onEnter.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('main-search-field-item', _vm._b({}, 'main-search-field-item', {
          item
        }, false))];
      }
    }])
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    itemText: _vm.itemText,
    loading: _vm.loading,
    menuProps: _vm.menuProps
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }