var render = function render(){
  var _vm$review, _vm$images, _vm$review2, _vm$review3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex-grow": "1"
    }
  }, [_c('div', {
    staticClass: "mb-8 mb-md-10 ellip"
  }, [_c('strong', {
    staticClass: "font-size-18 font-size-md-24 grey-2--text"
  }, [_vm._v(_vm._s((_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : _vm$review.subject))])]), _c('div', {
    staticStyle: {
      "position": "relative",
      "flex-grow": "1"
    }
  }, [!_vm.showsDetail ? _c('v-row', {
    staticClass: "row--x-small",
    class: {
      'content-area-before': true
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('v-col', {
    class: {
      'content-area-before-text': true,
      blurred: !_vm.isLoggedIn
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })]), !_vm.isBiggerImage && !!((_vm$images = _vm.images) !== null && _vm$images !== void 0 && _vm$images.length) ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('imgs-dialog', {
    attrs: {
      "imgs": _vm.images,
      "disabled": _vm.isBest || _vm.isLink || !_vm.isLoggedIn
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$images2, _vm$images3, _vm$images4;
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "rounded",
          staticStyle: {
            "overflow": "hidden",
            "cursor": "pointer"
          },
          attrs: {
            "src": (_vm$images2 = _vm.images) === null || _vm$images2 === void 0 ? void 0 : _vm$images2[0],
            "lazy-src": (_vm$images3 = _vm.images) === null || _vm$images3 === void 0 ? void 0 : _vm$images3[0],
            "eager": "",
            "width": "90",
            "aspect-ratio": 1 / 1,
            "content-class": "d-flex justify-end align-end"
          }
        }, 'v-img', attrs, false), on), [_c('v-overlay', {
          attrs: {
            "slot": "placeholder",
            "absolute": "",
            "z-index": "0",
            "color": "grey lighten-2"
          },
          slot: "placeholder"
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "grey"
          }
        }, [_vm._v("mdi-image-off-outline")])], 1), _c('v-card', {
          staticClass: "d-flex align-center justify-center",
          staticStyle: {
            "border-radius": "10px 0px !important"
          },
          attrs: {
            "width": "26",
            "height": "26",
            "tile": "",
            "dark": "",
            "color": "rgba(0,0,0,0.5)"
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-light"
        }, [_vm._v(_vm._s((_vm$images4 = _vm.images) === null || _vm$images4 === void 0 ? void 0 : _vm$images4.length))])])], 1)];
      }
    }], null, false, 3784075728)
  })], 1) : _vm._e()], 1) : _vm._e(), !_vm.isLoggedIn ? _c('v-btn', {
    staticStyle: {
      "right": "50%",
      "bottom": "0",
      "transform": "translateX(50%)"
    },
    attrs: {
      "dark": "",
      "color": "grey",
      "absolute": ""
    }
  }, [_vm._v("로그인 후 확인하기")]) : _vm._e()], 1), _c('div', {
    ref: "content-box",
    class: {
      'content-area-after': true,
      blurred: !_vm.isLoggedIn
    },
    style: _vm.afterStyle
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s((_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : _vm$review2.content)
    }
  }), _c('div', {
    staticClass: "mt-20 overflow-hidden"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, _vm._l(((_vm$review3 = _vm.review) === null || _vm$review3 === void 0 ? void 0 : _vm$review3.photos) || [], function (photo) {
    return _c('v-col', {
      key: photo._id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "src": photo.url,
        "eager": "",
        "max-width": "1146",
        "width": "auto"
      }
    })], 1);
  }), 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }