<template>
    <v-row justify="center" align="center" class="row--small user-menu">
        <v-col v-if="accessToken" cols="auto" class="line-height-1">
            <notification-view />
        </v-col>
        <v-col cols="auto" class="line-height-1">
            <gnb-login-button></gnb-login-button>
        </v-col>
        <v-col cols="auto" class="line-height-1" v-if="accessToken">
            <router-link to="/mypage/myinfo" class="d-inline-flex align-center font-size-14 px-2 px-md-8">
                <span>마이페이지</span>
            </router-link>
        </v-col>
        <v-col cols="auto" class="line-height-1" v-else>
            <router-link to="/join" class="d-inline-flex align-center font-size-14 px-2 px-md-8">
                <span>회원가입</span>
            </router-link>
        </v-col>
        <v-col cols="auto" class="line-height-1">
            <router-link to="/center/notice" class="d-inline-flex align-center font-size-14 px-2 px-md-8">
                <span>고객센터</span>
            </router-link>
        </v-col>
    </v-row>
</template>

<script>
import GnbLoginButton from "@/components/client/login/gnb-login-button.vue";
import NotificationView from "../notification-view/notification-view.vue";

export default {
    components: {
        GnbLoginButton,
        NotificationView,
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
