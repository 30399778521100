<template>
    <v-tabs v-model="tab" left color="primary">
        <v-tab v-show="false"></v-tab>
        <v-tab>
            <div class="d-inline-flex align-center font-size-16">
                <v-badge v-if="unreads.length" :content="unreads.length" inline :color="tab == 1 ? 'primary' : 'grey'">미확인</v-badge>
                <template v-else>미확인</template>
            </div>
        </v-tab>
        <v-tab>
            <div class="d-inline-flex align-center font-size-16">
                <span>전체</span>
                <span class="font-size-14 ml-4">( {{ unreads.length }} / {{ notifications.length }} )</span>
            </div>
        </v-tab>
        <v-spacer />
        <v-btn small text height="100%" color="primary" @click="setIsReadAll" :disabled="!unreads.length">모두읽음</v-btn>
    </v-tabs>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
export default {
    props: ["value", "unreads", "notifications"],
    computed: {
        needsSync() {
            return this.value !== this.tab;
        },
    },
    data() {
        return {
            tab: 0,
        };
    },
    methods: {
        ...mapMutations(["setNotification"]),
        async setIsReadAll() {
            if (this.unreads.length) {
                try {
                    const receivedAt = Date.now();
                    await Promise.all(this.unreads.map(async ({ _id }) => await api.v1.eyes.notifications.put({ _id, receivedAt })?.notification));
                    alert("모두읽음 처리되었습니다.");
                    this.$emit("search");
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },
    },
    mounted() {
        this.tab = this.value;
    },
    watch: {
        tab() {
            if (this.needsSync) this.$emit("input", this.tab);
        },
        value() {
            if (this.needsSync) this.tab = this.value;
        },
    },
};
</script>

<style></style>
