<template>
    <main-section>
        <v-row>
            <v-col cols="12" md="6">
                <v-card rounded="xl" elevation="6">
                    <div class="pa-30 pa-md-40">
                        <v-row no-gutters align="center" justify="space-between">
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <i class="icon icon-comment mr-12"></i>
                                    <strong class="font-size-20 font-size-lg-24 grey-2--text">안과후기</strong>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <strong class="font-size-24 font-size-lg-44 line-height-1 primary--text">{{ counts.reviews }}</strong>
                                    <span class="font-size-16 font-size-lg-20 grey-a0--text ml-4 ml-lg-12">개</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card rounded="xl" elevation="6">
                    <div class="pa-30 pa-md-40">
                        <v-row no-gutters align="center" justify="space-between">
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <i class="icon icon-pharmacy mr-12"></i>
                                    <strong class="font-size-20 font-size-lg-24 grey-2--text">병원정보</strong>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <strong class="font-size-24 font-size-lg-44 line-height-1 primary--text">{{ counts.locations }}</strong>
                                    <span class="font-size-16 font-size-lg-20 grey-a0--text ml-4 ml-lg-12">건</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </main-section>
</template>

<script>
import api from '@/api';
import MainSection from "./main-section.vue";
export default {
    components: {
        MainSection,
    },
    data: () => ({
        reviews: 0,
        locations: 0,
        setting: null,
    }),
    computed: {
        counts() {
            let { reviews, locations } = this;

            const { counts } = this.setting?.main || {};
            if (counts?.usesAdjustment) {
                reviews += counts.reviews;
                locations += counts.locations;
            }

            return { reviews, locations };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            [{ totalCount: this.reviews }] = [(await api.v1.eyes.reviews.gets({ headers: { skip: 0, limit: 1 } }))?.summary];

            [{ totalCount: this.locations }] = [(await api.v1.eyes.locations.gets({ headers: { skip: 0, limit: 1 } }))?.summary];

            [{ setting: this.setting }] = [await api.v1.setting.get()];
        },
    },
};
</script>

<style></style>
