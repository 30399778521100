var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-image-banner"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (slide, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "main-image-banner__img",
      attrs: {
        "to": slide !== null && slide !== void 0 && slide.isOuterLink ? null : slide === null || slide === void 0 ? void 0 : slide.url,
        "href": slide !== null && slide !== void 0 && slide.isOuterLink ? slide === null || slide === void 0 ? void 0 : slide.url : null,
        "target": slide === null || slide === void 0 ? void 0 : slide.target
      }
    }, [_c('img', {
      attrs: {
        "src": slide === null || slide === void 0 ? void 0 : slide.image,
        "alt": "이미지"
      }
    })])], 1);
  }), 0)]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right"
  })])]);

}]

export { render, staticRenderFns }