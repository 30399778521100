var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "notification-item-message"
  }, [_vm.actor ? [_c('strong', [_vm._v(_vm._s(_vm.actor.nickname))]), _vm._v(" 님이 ")] : _vm._e(), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }