var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "main-search"
  }, [_c('div', {
    staticClass: "search-primary search-primary--lg search-primary--gra"
  }, [_c('div', {
    staticClass: "search-primary__form"
  }, [_c('main-search-field', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "'안과이름' 또는 '수술방법' 검색"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-10 mt-md-20"
  }, [_c('main-search-chips')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }