import { getCurrentPosition } from "@/plugins/getCurrentPosition";

const kakao = window?.kakao?.maps;
const { Geocoder, Status } = kakao?.services || {};
const geocoder = new Geocoder();

export const location = {
    namespaced: true,
    state: {
        lat: 37.4835924256371,
        lng: 127.032693842117,
        lvl: 3,
        bounds: { w: null, s: null, e: null, n: null },

        sido: null,
        gugun: null,
    },
    mutations: {
        setSido(state, sido) {
            state.sido = sido;
        },
        setGugun(state, gugun) {
            state.gugun = gugun;
        },
        setLvl(state, { lvl }) {
            state.lvl = +lvl;
        },
        setBounds(state, { w = null, s = null, e = null, n = null } = {}) {
            state.bounds = { w, s, e, n };
        },
        setCoordinates(state, { lat, lng }) {
            state.lat = +lat;
            state.lng = +lng;
        },
    },
    actions: {
        async getSidoGugun({ state, commit }, { lng, lat, callback = () => {} }) {
            try {
                // // 주소 셋업
                geocoder.coord2RegionCode(lng, lat, (results, status) => {
                    let sido = results?.[0]?.region_1depth_name;
                    if (sido.includes("북도") || sido.includes("남도")) sido = sido[0] + sido[2];
                    sido = sido.slice(0, 2);
                    commit("setSido", sido);
                    commit("setGugun", results?.[0]?.region_2depth_name.split(" ")[0]);
                    callback();
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getCurrentLocation({ state, commit }, { callback = () => {} } = {}) {
            try {
                // 로케이션 셋업
                const { coords } = await getCurrentPosition();
                const { latitude: lat, longitude: lng } = coords;
                commit("setCoordinates", { lat, lng });
                commit("setLvl", { lvl: 3 });

                // // 주소 셋업
                geocoder.coord2RegionCode(lng, lat, (results, status) => {
                    commit("setSido", results?.[0]?.region_1depth_name);
                    commit("setGugun", results?.[0]?.region_2depth_name);
                    callback();
                });
            } catch (error) {
                console.error(error);
                alert("위치 권한을 허용하신 뒤 다시 시도하세요");
            }
        },
        async getAddressLocation({ commit }, { sido = "", gugun = "", callback = () => {} }) {
            if (sido == "광주") sido = "광주광역시";
            geocoder.addressSearch(`${sido} ${gugun || ""}`, (results, status) => {
                if (status === Status.OK) {
                    commit("setSido", sido);
                    commit("setGugun", gugun);
                    commit("setCoordinates", { lat: parseFloat(results?.[0]?.y), lng: parseFloat(results?.[0]?.x) });
                    callback();
                } else alert("위치를 확인할 수 없습니다");
            });
        },
    },
    getters: {
        kakaomapCenterPosition: ({ lng, lat }) => {
            return { lng, lat };
        },
        geolocation: ({ lng, lat }) => ({
            type: "Point",
            coordinates: [lng, lat],
        }),
        geometry: ({ bounds: { w, s, e, n } }) => {
            if (!!w && !!s && !!e && !!n) {
                return {
                    type: "Polygon",
                    coordinates: [
                        [
                            [w, s],
                            [w, n],
                            [e, n],
                            [e, s],
                            [w, s],
                        ],
                    ],
                };
            } else return null;
        },
    },
};
