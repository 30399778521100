var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "pa-0",
    attrs: {
      "text": "",
      "dark": "",
      "x-small": ""
    },
    on: {
      "click": _vm.toggleLike
    }
  }, [_c('i', {
    class: _vm.iconClassName
  }), _c('span', {
    class: _vm.textClassName
  }, [_vm._v(_vm._s(_vm.likesCount))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }