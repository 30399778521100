<template>
    <v-expand-transition v-if="review?.doctor">
        <div v-show="opened || showsDetail" class="mt-16 mt-md-20">
            <v-row no-gutters align="center" class="font-size-14">
                <v-col cols="auto">
                    <div class="w-80px">
                        <b class="grey-2--text">담당 선생님</b>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <span class="grey-7--text">{{ review.doctor }}</span>
                </v-col>
            </v-row>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    props: {
        review: { type: Object, default: () => ({}) },
        opened: { type: Boolean, default: false },
        showsDetail: { type: Boolean, default: false },
    },
};
</script>

<style></style>
