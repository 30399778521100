var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-visual"
    }
  }), _c('main-visual', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-visual-mo"
    }
  }), _c('main-search'), _c('div', {
    staticClass: "main-review-banner mt-40 mt-md-60"
  }, [_c('v-container', [_c('review-list-best', {
    attrs: {
      "isMain": ""
    }
  })], 1)], 1), _c('main-counts'), _c('review-list-recent'), _c('main-wide-image-banner', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-wide-image-banner"
    }
  }), _c('main-wide-image-banner', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-wide-image-banner-mo"
    }
  }), _c('main-section', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--more mb-14"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("공지사항")]), _c('v-btn', {
    staticClass: "px-6",
    attrs: {
      "color": "transparent",
      "to": "/center/notice"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("더 보기 +")])])], 1), _c('v-divider', {
    staticClass: "border-2 grey-2"
  }), _c('div', {
    staticClass: "h-100 px-10 py-20"
  }, [_c('main-notice')], 1)], 1), _c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--more mb-14"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("FAQ")]), _c('v-btn', {
    staticClass: "px-6",
    attrs: {
      "color": "transparent",
      "to": "/center/faq"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("더 보기 +")])])], 1), _c('v-divider', {
    staticClass: "border-2 grey-2"
  }), _c('div', {
    staticClass: "h-100 px-10 py-20",
    attrs: {
      "fill-height": ""
    }
  }, [_c('main-faq')], 1)], 1), _c('v-divider')], 1)], 1)], 1), _c('main-section', {
    staticClass: "pt-0 main-section--image-banner"
  }, [_c('main-image-banner', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-image-banner"
    }
  }), _c('main-image-banner', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-image-banner-mo"
    }
  })], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }