<template>
    <main-section class="grey-f9">
        <div class="tit-wrap tit-wrap--subtitle tit-wrap--more text-center">
            <small>review</small>
            <h2 class="tit">최신 후기</h2>
            <v-btn color="transparent" class="px-6" to="/reviews"><span class="primary--text">전체보기 +</span></v-btn>
        </div>
        <div>
            <v-row>
                <v-col cols="12" sm="6" lg="4" v-for="review in reviews" :key="review._id">
                    <review-item v-bind="{ review }" isBest />
                </v-col>
            </v-row>

            <div class="mt-40 mt-md-70" v-if="hasMore">
                <div class="v-btn--group">
                    <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
                </div>
            </div>
        </div>
    </main-section>
</template>

<script>
import api from "@/api";
import ReviewItem from "./review-item.vue";
import MainSection from "../main/main-section.vue";

export default {
    components: {
        ReviewItem,
        MainSection,
    },
    data: () => ({
        sort: "createdAt",
        searchKey: null,
        searchValue: null,

        page: 1,
        limit: 9,
        totalCount: 0,

        reviews: [],
    }),
    computed: {
        mode() {
            return this.$route.params.mode;
        },
        keyword() {
            return this.$route.params.keyword;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        hasMore() {
            return this.totalCount > this.reviews.length;
        },
    },
    mounted() {
        if (this.keyword) this.searchValue = this.keyword;
        this.init().then(this.search);
    },
    methods: {
        go() {
            this.$router.push(`/map/reviews/${this.searchValue}`);
        },
        async init() {
            this.page = 1;
            this.totalCount = 0;
            this.reviews = [];
        },
        async search() {
            let { skip, limit, sort, searchKey, searchValue } = this;
            const regexp = /^＃([\S]+)/;
            if (regexp.test(searchValue)) {
                searchKey = "services";
                searchValue = searchValue.match(regexp)[1];
            }
            const {
                summary: { totalCount },
                reviews,
            } = await api.v1.eyes.reviews.gets({
                headers: { skip, limit },
                params: searchValue ? { sort, searchKey, searchValue } : { sort },
            });

            this.reviews = this.reviews.concat(reviews);
            this.totalCount = totalCount;
        },
        loadMore() {
            this.page += 1;
            this.search();
        },
    },
};
</script>
