import Vue from "vue";
import Vuex from "vuex";
import axios from "../plugins/axios";
import api from "../api";
import { location } from "./location";

Vue.use(Vuex);

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var scope = payload?.scope || [];

export default new Vuex.Store({
    state: {
        refreshToken,
        accessToken,
        payload,
        scope,

        likes: undefined,

        agreements: JSON.parse(sessionStorage.getItem("agreements") || "[]"),
    },
    mutations: {
        agreements(state, agreements) {
            state.agreements = agreements;

            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        login(state, { refreshToken, accessToken }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
            state.scope = state.payload?.scope || [];

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("accessToken", accessToken);
        },

        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.likes = undefined;

            axios.defaults.headers.common["Authorization"] = "";

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
        },

        setLikes(state, { likes }) {
            state.likes = likes;
        },
    },
    actions: {
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },

        async login({ commit, dispatch }, { username, password }) {
            var { refreshToken } = await api.auth.getRefreshToken({ username, password });
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
            dispatch("getLikes");
        },

        logout({ commit }) {
            commit("logout");
        },

        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },

        async getLikes({ commit }) {
            const { likes } = await api.v1.me.likes.gets();
            commit("setLikes", { likes });
        },
    },
    modules: {
        location,
    },
});
