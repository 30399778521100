<template>
    <div>
        <v-row no-gutters align="baseline">
            <v-col cols="auto">
                <strong class="font-size-16 font-size-md-18 grey-2--text">
                    댓글 <span class="primary--text">{{ summary?.totalCount }}</span>
                </strong>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <review-report-form v-bind="{ review }">
                    <template #activator="{attrs, on}">
                        <v-btn text class="pa-0" height="21" x-small v-bind="attrs" v-on="on">
                            <v-icon size="21" color="grey-7">mdi-alarm-light</v-icon>
                            <span class="font-size-14 font-weight-regular grey-7--text ml-4">신고하기</span>
                        </v-btn>
                    </template>
                </review-report-form>
            </v-col>
        </v-row>
        <v-divider class="border-2 grey-2 mt-8 mt-md-14" />

        <comments :value="items" @save="save" @remove="remove" />

        <div v-if="isLoggedIn" class="py-16">
            <comment-input v-if="isLoggedIn" relative @save="save" />
        </div>
    </div>
</template>

<script>
import Comments from "@/components/dumb/comments.vue";
import CommentInput from "@/components/dumb/comment-input.vue";
import ReviewReportForm from "./review-report-form.vue";

import api from "@/api";

export default {
    components: {
        Comments,
        CommentInput,
        ReviewReportForm,
    },
    props: {
        review: { type: Object, default: () => ({}) },
        _review: { type: String, default: null },
    },
    data: () => ({
        comments: [],
        summary: { totalCount: 0 },
    }),
    computed: {
        items() {
            const { parents = [], children = [] } = this.comments.map((comment) => ({ ...comment, user: comment.writer })).reduce(({ parents = [], children = [] }, comment) => (comment?._comment ? { parents, children: [...children, comment] } : { parents: [...parents, comment], children }), {});

            return (function makeSort(siblings, remained, depth = 0, result = []) {
                for (const parent of siblings) {
                    const children = remained.filter(({ _comment }) => _comment == parent._id);
                    const nextRemained = remained.filter(({ _comment }) => _comment !== parent._id);
                    result.push({ ...parent, depth, children: makeSort(children, nextRemained, depth + 1) });
                }
                return result;
            })(parents, children);
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        "summary.totalCount"() {
            this.$emit("syncComments", this.summary.totalCount);
        },
    },
    methods: {
        async search() {
            const { _review } = this;
            const { summary, comments } = await api.v1.eyes.reviews.comments.gets({ _review });
            this.summary = summary;
            this.comments = comments;
        },
        async save({ callback = () => {}, ...item } = {}) {
            const isPut = !!item?._id;
            const { _review } = this;
            console.log({ item, _review });
            const { comment } = isPut ? await api.v1.eyes.reviews.comments.put({ _id: item?._id, _review, content: item?.content, mention: item?.mention || null }) : await api.v1.eyes.reviews.comments.post({ ...item, _review });

            if (isPut) {
                const index = this.comments.findIndex(({ _id }) => _id == item?._id);
                this.comments.splice(index, 1, comment);
            } else {
                this.comments = [comment, ...this.comments];
                this.summary.totalCount += 1;
            }

            alert("성공적으로 처리되었습니다");
            callback();
        },
        async remove(item) {
            if (confirm("삭제하시겠습니까?")) {
                await api.v1.eyes.reviews.comments.delete(item);

                const targetIndex = this.comments.findIndex(({ _id }) => _id == item._id);
                this.comments.splice(targetIndex, 1);
                this.summary.totalCount -= 1;

                alert("성공적으로 처리되었습니다");
            }
        },
    },
};
</script>

<style scoped></style>
