<template>
    <div v-if="timestamp" class="notification-item-timestamp">{{ timestamp }}</div>
</template>

<script>
import { NOTIFICATION_TYPES } from "@/assets/variables";
import dayjs from "dayjs";
const { PROJECT_START, WORK_START, WORK_COMMENT, WORK_APPROVAL, ISSUE_OPEN, ISSUE_COMMENT, ISSUE_CLOSE, QUOTE_REQUEST, QUOTE_RESPOND, QUOTE_RESPOND_COMMENT } = NOTIFICATION_TYPES;

export default {
    props: ["notification"],
    computed: {
        timestamp() {
            const { createdAt } = this.notification || {};
            return dayjs(createdAt).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>

<style scoped>
.notification-item-timestamp {
    font-size: 1.2rem;
    color: #aaa;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
</style>
