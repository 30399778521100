var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', {
    staticClass: "grey-f9"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--subtitle tit-wrap--more text-center"
  }, [_c('small', [_vm._v("review")]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v("최신 후기")]), _c('v-btn', {
    staticClass: "px-6",
    attrs: {
      "color": "transparent",
      "to": "/reviews"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("전체보기 +")])])], 1), _c('div', [_c('v-row', _vm._l(_vm.reviews, function (review) {
    return _c('v-col', {
      key: review._id,
      attrs: {
        "cols": "12",
        "sm": "6",
        "lg": "4"
      }
    }, [_c('review-item', _vm._b({
      attrs: {
        "isBest": ""
      }
    }, 'review-item', {
      review
    }, false))], 1);
  }), 1), _vm.hasMore ? _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("더 보기")])], 1)]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }