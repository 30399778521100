<template>
    <div v-show="comment" class="notification-item-comment">"{{ comment }}"</div>
</template>

<script>
export default {
    props: ["notification"],
    computed: {
        comment() {
            const { content } = this.notification?.comment || {};
            if (content) return content;
            else return;
        },
    },
};
</script>

<style scoped>
.notification-item-comment {
    color: var(--v-primary-base);
    font-size: 1.4rem;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
</style>
