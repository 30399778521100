<template>
    <v-chip-group v-bind="$attrs" column class="justify-center">
        <v-chip v-for="item in items" :key="item._id" large outlined color="grey-2" :to="to(item)">{{ item.tag }}</v-chip>
    </v-chip-group>
</template>

<script>
import api from "@/api";
import { SERVICES, TAG_TYPES } from "@/assets/variables";

export default {
    data: () => ({
        tags: [],
    }),
    computed: {
        items() {
            return this.tags
                .map((value) => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value);
        },
    },
    mounted() {
        this.init();
    },
    watch: {},
    methods: {
        async init() {
            const { tags } = await api.v1.eyes.tags.gets({ params: { isOn: true } });
            this.tags = tags;
        },
        to({ type, text, _location, _id: _tags }) {
            if (type == TAG_TYPES.address.value) {
                const [sido, gugun] = text?.split?.(" ") || [];

                return { path: "/locations", query: { sido, gugun } };
            }
            if (type == TAG_TYPES.location.value) {
                return `/locations/${_location}`;
            }
            if (type == TAG_TYPES.service.value) {
                return { path: "/reviews", query: { keyword: `＃${SERVICES[text]?.text}` } };
            }
            if (type == TAG_TYPES.custom.value) {
                return { path: "/reviews", query: { _tags } };
            }
            return "/reviews";
        },
    },
};
</script>
