var render = function render(){
  var _vm$review, _vm$review$meta, _vm$review2, _vm$review2$meta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, [_vm.index ? _c('v-chip', {
    staticClass: "font-size-40 font-weight-black mx-n10",
    attrs: {
      "color": "transparent",
      "text-color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.index) + " ")]) : _vm._e(), (_vm$review = _vm.review) !== null && _vm$review !== void 0 && (_vm$review$meta = _vm$review.meta) !== null && _vm$review$meta !== void 0 && _vm$review$meta.isBest ? _c('v-chip', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "red",
      "text-color": "white"
    }
  }, [_vm._v("Best 리뷰")]) : _vm._e(), (_vm$review2 = _vm.review) !== null && _vm$review2 !== void 0 && (_vm$review2$meta = _vm$review2.meta) !== null && _vm$review2$meta !== void 0 && _vm$review2$meta.hasReceipt ? _c('v-chip', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "secondary",
      "text-color": "white"
    }
  }, [_vm._v("영수증 인증")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }