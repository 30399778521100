<template>
    <ul v-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className+'__li'" :key="item.title">
                <router-link :to="item.path" :class="className+'__link'"><h2>{{item.title}}</h2></router-link>
            </li>

            <li v-else :class="className+'__li'" :key="item.title">
                <!-- <router-link :to="item.path" :class="className+'__link gnb__link--toggle'"><h2>{{item.title}}</h2></router-link> -->
                <div :class="className+'__link gnb__link--toggle'"><h2>{{item.title}}</h2></div>
                <ul :class="className+'__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className : String,
        indexNum : String,
    },
    data: function(){
        return {
            gnbs : [
                {
                    title : "안과찾기",
                    path : "/map",
                    children : [
                        {
                            title : "지도보기",
                            path : "/map",
                        },
                        {
                            title : "목록보기",
                            path : "/locations",
                        },
                    ]
                },
                {
                    title : "안과후기",
                    path : "/reviews",
                },
                {
                    title : "후기쓰기",
                    path : "/reviews/write",
                },
                {
                    title : "커뮤니티",
                    path : "/community/boards",
                    children : [
                        {
                            title : "게시판",
                            path : "/community/boards",
                        },
                        {
                            title : "이벤트",
                            path : "/community/events",
                        },
                    ]
                },
            ],
        };
    },
    created: function(){
    },
    mounted: function(){
    },
    methods: {
    }
};
</script>