<template>
    <client-page class="app--main">
        <!-- S: 메인 비주얼 -->
        <main-visual code="main-visual" class="d-none d-md-block"></main-visual>
        <main-visual code="main-visual-mo" class="d-md-none"></main-visual>
        <!-- E: 메인 비주얼 -->

        <!-- S: 검색영역 -->
        <main-search />
        <!-- E: 검색영역 -->

        <!-- S: Review Banner -->
        <div class="main-review-banner mt-40 mt-md-60">
            <v-container>
                <review-list-best isMain />
            </v-container>
        </div>
        <!-- E: Review Banner -->

        <!-- S: 후기정보 -->
        <main-counts />
        <!-- E: 후기정보 -->

        <!-- S: 최신후기 -->
        <review-list-recent />
        <!-- E: 최신후기 -->

        <!-- S:메인배너 -->
        <main-wide-image-banner code="main-wide-image-banner" class="d-none d-md-block"></main-wide-image-banner>
        <main-wide-image-banner code="main-wide-image-banner-mo" class="d-md-none"></main-wide-image-banner>
        <!-- E:메인배너 -->

        <!-- S: 게시판 -->
        <main-section>
            <v-row>
                <v-col cols="12" md="6">
                    <v-layout column fill-height>
                        <div class="tit-wrap tit-wrap--more mb-14">
                            <h2 class="tit tit--sm">공지사항</h2>
                            <v-btn color="transparent" to="/center/notice" class="px-6"><span class="primary--text">더 보기 +</span></v-btn>
                        </div>
                        <v-divider class="border-2 grey-2"></v-divider>
                        <div class="h-100 px-10 py-20">
                            <main-notice />
                        </div>
                    </v-layout>
                    <v-divider />
                </v-col>
                <v-col cols="12" md="6">
                    <v-layout column fill-height>
                        <div class="tit-wrap tit-wrap--more mb-14">
                            <h2 class="tit tit--sm">FAQ</h2>
                            <v-btn color="transparent" to="/center/faq" class="px-6"><span class="primary--text">더 보기 +</span></v-btn>
                        </div>
                        <v-divider class="border-2 grey-2"></v-divider>
                        <div fill-height class="h-100 px-10 py-20">
                            <main-faq />
                        </div>
                    </v-layout>
                    <v-divider />
                </v-col>
            </v-row>
        </main-section>
        <!-- E: 게시판 -->

        <!-- S: 배너슬라이드 -->
        <main-section class="pt-0 main-section--image-banner">
            <main-image-banner code="main-image-banner" class="d-none d-md-block"></main-image-banner>
            <main-image-banner code="main-image-banner-mo" class="d-md-none"></main-image-banner>
        </main-section>
        <!-- E: 배너슬라이드 -->

        <!-- <v-row justify="center">
            <v-col cols="12" align="center" class="py-0">
                <span class="subtitle-1 font-weight-bold">다국어 샘플</span> <br>
                <span class="caption">(해당 내용은 테스트하고 지우면되요)</span>
            </v-col>
            <v-col cols="auto" class="py-0">
                <locale-select label="언어변경"></locale-select>
            </v-col>
            <v-col cols="12" align="center">
                <div> 한국어 번역 경로: src/locales/ko.js </div>
                <div> 영어 번역 경로: src/locales/en.js </div>
                <div> 중국어 번역 경로: src/locales/cn.js </div>
                <div class="mt-4"> 사용법 $t() 함수 안에, src/locales/{locale}.js 에서 작성한 json 키워드 나열 </div>
                <div class="font-weight-bold mt-4">JSON 작성법1</div>
                <div>
                    {
                        "key1": "value1"
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법2</div>
                <div>
                    {
                        "key1": {
                            "key2": "value2"
                        }
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법3 - 우리 회사 룰 대략 아래처럼 정하죠 </div>
                <div>
                    {
                        page: {
                            section1: "내용1",
                            section2: {
                                title: "타이틀",
                                content: "내용"
                            }
                        }
                    }
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example1</div>
                    <div class="body-1">$t("common.로그인")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("common.로그인") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example2</div>
                    <div class="body-1">$t("main.section1")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section1") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example3</div>
                    <div class="body-1">$t("main.section2.title")</div>
                    <div class="body-1">$t("main.section2.content")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.title") }}</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.content") }}</div>
                </div>
            </v-col>
        </v-row> -->
        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import MainFaq from "@/components/client/main/main-faq.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainSearch from "@/components/client/main/main-search.vue";
import MainCounts from "@/components/client/main/main-counts.vue";
import MainNotice from "@/components/client/main/main-notice.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainSection from "@/components/client/main/main-section.vue";
import LocaleSelect from "@/components/client/inc/locale-select.vue";
import ReviewListBest from "@/components/client/reviews/review-list-best.vue";
import MainImageBanner from "@/components/client/main/main-image-banner.vue";
import ReviewListRecent from "@/components/client/reviews/review-list-recent.vue";
import MainWideImageBanner from "@/components/client/main/main-wide-image-banner.vue";
export default {
    components: {
        MainFaq,
        ClientPage,
        MainVisual,
        MainSearch,
        MainCounts,
        MainNotice,
        PopupLayer,
        MainSection,
        LocaleSelect,
        ReviewListBest,
        MainImageBanner,
        ReviewListRecent,
        MainWideImageBanner,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
};
</script>
