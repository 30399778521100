<template>
    <footer class="footer">
        <v-container>
            <v-row align="center" class="row--small">
                <v-col>
                    <div class="mb-14 mb-md-20">
                        <ul class="footer-link">
                            <li><a href="/terms">이용약관</a></li>
                            <li><a href="/medical">의료정보운영방침 </a></li>
                            <li><a href="/email">이메일주소수신거부 </a></li>
                            <li><a href="/privacy">개인정보처리방침</a></li>
                        </ul>
                    </div>
                    <!-- <div>
                        <ul class="footer-info">
                            <li><span>상호 </span>위어스코리아</li>
                            <li><span>주소 </span>서울특별시 강남구 테헤란로 128, 3층 396호(역삼동, 성곡빌딩)</li>
                            <li><span>연락처 </span>010-7471-3469</li>
                            <li><span>사업자번호 </span>405-86-01983</li>
                        </ul>
                    </div> -->
                    <div class="mt-10">
                        <p class="copyright">© 2022. EYE REVIEW Co. all rights reserved.</p>
                    </div>
                </v-col>
                <v-col cols="auto" class="d-none d-xl-block">
                    <v-img width="220" src="/images/logo.svg" style="opacity:.2;"></v-img>
                </v-col>
                <!-- <v-col cols="12" md="auto" order="first" order-md="last">
                    <v-select large hide-details outlined placeholder="Family Site" append-icon="mdi-chevron-down" class="familysite"
                    :items="sites" item-text="name" item-value="link" v-model="site" @change="changeD">
                    </v-select>
                </v-col> -->
            </v-row>
        </v-container>
        <u-scroll-top></u-scroll-top>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";
export default {
    components: {
        UScrollTop,
    },
    data(){
        return {
            // site : null,
            // sites : [
            //     {
            //         name:"네이버",
            //         link:"https://www.naver.com/"
            //     },
            //     {
            //         name:"다음",
            //         link:"https://www.daum.net/"
            //     },
            // ]
        };
    },
    mounted(){
    },
    methods: {
        // changeD : function() {
        //     window.open(this.site, '_blank');
        // }
    }
}
</script>

<style scoped>
.footer{
    color: #a0a8c1;
    background-color: #1f232f;
    padding:40px 0;
}
.footer .container{
    max-width: calc(var(--container-lg) + 24px);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .footer{
        padding:70px 0;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
.footer-link {
    display: flex;
    flex-wrap: wrap;
    margin:-4px -10px;
}
.footer-link li {
    position: relative;
    padding: 4px 10px;
}
.footer-link li::before{
    display: none;
    content:'';
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
    width: 2px;
    height: 2px;
    background-color: #fff;
}
.footer-link li:first-child::before{display: none;}
.footer-link li a {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2;
    color: #fff;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .footer-link {  margin:0 -14px; }
    .footer-link li { padding: 0 14px; }
    .footer-link li::before{
        display:block;
    }
    .footer-link li a {
        font-size: 1.6rem;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
.footer-info{
    font-size: 1.2rem;
    margin:-2px -6px;
    overflow:auto;
}
.footer-info::after{content:"";clear:both;display:table;}
.footer-info li{
    float: left;
    display: inline-block;
    padding:2px 6px;
}
.footer-info li >span{
    margin-right: 4px;
    color:#fff;
}
.footer-info__tit{
    font-weight: 500;
    color:#fff;
    margin-bottom: 2px;
}
@media (min-width:576px){
    .footer-info{
        font-size: 1.4rem;
    }
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
.copyright{
    font-size: 1.2rem;
    color:#a0a8c1;
}
.familysite{
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #fff;
    border-radius: 0;
}
.familysite .v-input__slot{
    height: 36px !important;
}
.familysite .v-text-field--outlined legend{
    border:none;
}
.v-application .familysite, .familysite input::placeholder, .familysite .v-select__selection--comma, .familysite .mdi:before{
    color:#fff !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .copyright{
        font-size: 1.4rem;
    }
    .familysite .v-input__slot{
        height: 44px !important;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
