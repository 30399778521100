<template>
    <v-card rounded="xl" :outlined="!isBest" :elevation="isBest ? 6 : 0" v-bind="{ to }" :class="{ 'v-card--default': !isLocationView }">
        <v-card-text class="d-flex flex-column px-16 py-20 px-md-26 py-md-36">
            <v-row no-gutters justify="space-between">
                <v-col :cols="isBiggerImage ? 10 : 'auto'" class="d-flex flex-column">
                    <div class="mb-8 mb-md-18">
                        <review-item-chips v-bind="{ review, index }" />
                    </div>
                    <div class="mb-6 mb-md-12">
                        <div class="d-flex align-center">
                            <span class="font-size-14 font-size-md-16 primary--text">{{ serviceName }}</span>
                            <span class="font-size-12 font-size-md-14 grey-a--text ml-6">{{ review?.writer?.nickname }}님</span>
                        </div>
                    </div>

                    <review-item-texts v-bind="{ review, opened, showsDetail, isBest, isLink, isBiggerImage, isLoggedIn }" @showsDetail="(bool) => (showsDetail = bool)" class="v-card--default__content" />
                </v-col>
                <v-col :cols="isBiggerImage ? 2 : 'auto'" v-if="isBiggerImage && !showsDetail">
                    <div class="pl-10 pl-16">
                        <imgs-dialog :imgs="images" :disabled="isBest || isLink || !isLoggedIn">
                            <template #activator="{ attrs, on }">
                                <v-img v-bind="attrs" v-on="on" :src="images?.[0]" class="rounded w-100px w-sm-180px" :aspect-ratio="1 / 1" style="overflow: hidden; cursor: pointer" content-class="d-flex justify-end align-end">
                                    <v-overlay slot="placeholder" absolute z-index="0" color="grey lighten-2">
                                        <v-icon x-large color="grey">mdi-image-off-outline</v-icon>
                                    </v-overlay>

                                    <v-card width="36" height="36" tile dark color="rgba(0,0,0,0.5)" class="d-flex align-center justify-center" style="border-radius: 12px 0px !important">
                                        <span class="font-size-12 font-weight-light">{{ images?.length }}</span>
                                    </v-card>
                                </v-img>
                            </template>
                        </imgs-dialog>
                    </div>
                </v-col>
            </v-row>

            <div :class="{ blurred: !isLoggedIn }">
                <review-item-stars v-bind="{ review, opened, showsDetail }" />
                <review-item-doctor v-bind="{ review, opened, showsDetail }" />
                <review-item-charges v-bind="{ review, opened, showsDetail }" />
            </div>

            <div v-if="!isLocationView" class="pt-8 pt-md-14 mt-auto mb-n4">
                <v-row no-gutters justify="space-between" align="end">
                    <v-col cols="auto">
                        <div v-if="!!review?.location" class="font-size-14 grey-a--text">{{ review?.location?.areaName }}</div>
                        <router-link v-if="!!review?.location" class="d-flex align-center" :to="`/locations/${review._location}`">
                            <span class="font-size-14 font-size-md-16 font-weight-bold grey-2--text">{{ review?.locationName }}</span>
                            <v-icon class="font-size-md-18 grey-2--text mt-n2 ml-4">mdi-chevron-right</v-icon>
                        </router-link>
                    </v-col>
                    <v-col cols="auto">
                        <v-rating :value="(review?.location?.meta?.starsAverage?.total || 0) / 10" readonly half-increments color="warning" background-color="warning" dense />
                    </v-col>
                </v-row>
            </div>

            <v-expand-transition v-if="review">
                <div v-show="showsDetail" class="mt-20 mt-md-30">
                    <review-item-comments v-bind="{ review, _review }" @syncComments="(num) => (commentsLength = num)" />
                </div>
            </v-expand-transition>
        </v-card-text>

        <v-card-actions class="pa-16 px-md-26 primary white--text">
            <v-row align="center" class="row--small">
                <v-col cols="auto">
                    <review-like-button v-bind="{ review }" />
                </v-col>
                <v-col cols="auto">
                    <div class="d-flex align-center">
                        <i class="icon icon-comment-outline"></i>
                        <span class="font-size-14 ml-4">{{ commentsLength }}</span>
                    </div>
                </v-col>
                <!-- <v-spacer />
                <v-col cols="auto" v-if="isLoggedIn && showsDetail">
                    <review-report-form v-bind="{ review }">
                        <template #activator="{attrs, on}">
                            <v-btn text dark class="py-0" x-small v-bind="attrs" v-on="on">
                                <v-icon dense size="22">mdi-alarm-light</v-icon>
                                <span class="ml-4 font-size-14 font-weight-regular">신고하기</span>
                            </v-btn>
                        </template>
                    </review-report-form>
                </v-col> -->
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import ImgsDialog from "@/components/dumb/imgs-dialog.vue";
import ReviewItemChips from "./review-item-chips.vue";
import ReviewItemTexts from "./review-item-texts.vue";
import ReviewItemStars from "./review-item-stars.vue";
import ReviewItemDoctor from "./review-item-doctor.vue";
import ReviewLikeButton from "@/components/dumb/review-like-button.vue";
// import ReviewReportForm from "./review-report-form.vue";
import ReviewItemCharges from "./review-item-charges.vue";
import ReviewItemComments from "./review-item-comments.vue";
import { SERVICES } from "@/assets/variables";
export default {
    components: {
        ImgsDialog,
        ReviewItemChips,
        ReviewItemTexts,
        ReviewItemStars,
        ReviewItemDoctor,
        ReviewLikeButton,
        // ReviewReportForm,
        ReviewItemCharges,
        ReviewItemComments,
    },
    props: {
        review: { type: Object, default: () => ({}) },

        index: { type: [Number, String, Boolean], default: false },
        opened: { type: Boolean, default: false },
        isLink: { type: Boolean, default: false },
        isBest: { type: Boolean, default: false },
        isBiggerImage: { type: Boolean, default: false },
        isLocationView: { type: Boolean, default: false },
    },
    data: () => ({
        showsDetail: false,
        commentsLength: 0,
    }),
    computed: {
        serviceName() {
            let name = SERVICES[this.review?.services?.[0]]?.text;
            let { length } = this.review?.services || [];
            length -= 1;
            if (length > 0) name += ` 외 ${length}`;
            return name;
        },
        _location() {
            return this.review?._location;
        },
        _review() {
            return this.review?._id;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        to() {
            if (!this.isLoggedIn) return "/login";
            else if (this.isBest || this.isLink) return `/reviews/${this.review?._id}`;
            else return;
        },
        images() {
            const { photos = [], images = [] } = this.review || {};
            if (photos.length) return photos.map(({ url }) => url);
            else return images;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        opened() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.showsDetail = this.opened;
            this.commentsLength = (this.review?._comments || [])?.length;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
}
</style>
