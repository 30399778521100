var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.comment,
      expression: "comment"
    }],
    staticClass: "notification-item-comment"
  }, [_vm._v("\"" + _vm._s(_vm.comment) + "\"")]);

}
var staticRenderFns = []

export { render, staticRenderFns }