var render = function render(){
  var _vm$value, _vm$value$user, _vm$value2, _vm$value3, _vm$value4, _vm$value4$createdAt, _vm$value4$createdAt$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: {
      'light-blue lighten-5': _vm.isRouted
    }
  }, [_c('div', {
    ref: "comment",
    class: {
      'mx-n16 mx-md-n26 px-36 px-md-50 py-16 py-md-20': true
    }
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('strong', {
    staticClass: "font-size-16 font-weight-bold grey-2--text"
  }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$user = _vm$value.user) === null || _vm$value$user === void 0 ? void 0 : _vm$value$user.nickname))])]), _c('div', [_vm.isEdit ? _c('comment-input', {
    attrs: {
      "usesCustomActivator": "",
      "buttonText": "수정"
    },
    on: {
      "save": function (item) {
        return (_vm.$emit('save', item) || true) && (_vm.isEdit = !_vm.isEdit);
      }
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }) : [_c('div', {
    staticClass: "font-size-14"
  }, [(_vm$value2 = _vm.value) !== null && _vm$value2 !== void 0 && _vm$value2.mention ? _c('span', {
    staticClass: "primary--text mr-10"
  }, [_vm._v(_vm._s(_vm.value.mention))]) : _vm._e(), _c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.content))])])]], 2), _c('div', {
    staticClass: "mt-8"
  }, [_c('v-row', {
    staticClass: "font-size-14 grey-a--text",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$createdAt = _vm$value4.createdAt) === null || _vm$value4$createdAt === void 0 ? void 0 : (_vm$value4$createdAt$ = _vm$value4$createdAt.toAgo) === null || _vm$value4$createdAt$ === void 0 ? void 0 : _vm$value4$createdAt$.call(_vm$value4$createdAt)))]), _vm.isLoggedIn ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mx-8"
  }, [_vm._v("·")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-a"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('mention', _vm.value);
      }
    }
  }, [_vm._v("답글달기")])], 1)] : _vm._e(), _vm.isUser ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mx-8"
  }, [_vm._v("·")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-a"
    },
    on: {
      "click": function ($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_vm._v("수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mx-8"
  }, [_vm._v("·")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-a"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove', _vm.value);
      }
    }
  }, [_vm._v("삭제")])], 1)] : _vm._e()], 2)], 1)]), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }