var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notification-item",
    class: _vm.className,
    on: {
      "click": _vm.show
    }
  }, [_c('div', {
    staticClass: "notification-item__inner"
  }, [_c('v-row', {
    class: _vm.className,
    attrs: {
      "no-gutters": ""
    },
    on: {
      "click": _vm.show
    }
  }, [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('notification-item-avatar', _vm._b({}, 'notification-item-avatar', {
    notification: _vm.notification
  }, false))], 1), _c('v-col', {
    staticClass: "notification-item__con"
  }, [_c('div', [_c('notification-item-title', _vm._b({}, 'notification-item-title', {
    notification: _vm.notification
  }, false)), _c('notification-item-subtitle', _vm._b({}, 'notification-item-subtitle', {
    notification: _vm.notification
  }, false)), _c('notification-item-message', _vm._b({}, 'notification-item-message', {
    notification: _vm.notification
  }, false)), _c('notification-item-comment', _vm._b({}, 'notification-item-comment', {
    notification: _vm.notification
  }, false)), _c('notification-item-timestamp', _vm._b({}, 'notification-item-timestamp', {
    notification: _vm.notification
  }, false))], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg h-auto min-w-auto py-0 px-4",
    attrs: {
      "text": "",
      "color": "red",
      "small": ""
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.remove.apply(null, arguments);
      }
    }
  }, [_vm._v("삭제")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "notification-item-divider"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }