var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "left": "",
      "color": "primary"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }), _c('v-tab', [_c('div', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_vm.unreads.length ? _c('v-badge', {
    attrs: {
      "content": _vm.unreads.length,
      "inline": "",
      "color": _vm.tab == 1 ? 'primary' : 'grey'
    }
  }, [_vm._v("미확인")]) : [_vm._v("미확인")]], 2)]), _c('v-tab', [_c('div', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_c('span', [_vm._v("전체")]), _c('span', {
    staticClass: "font-size-14 ml-4"
  }, [_vm._v("( " + _vm._s(_vm.unreads.length) + " / " + _vm._s(_vm.notifications.length) + " )")])])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "height": "100%",
      "color": "primary",
      "disabled": !_vm.unreads.length
    },
    on: {
      "click": _vm.setIsReadAll
    }
  }, [_vm._v("모두읽음")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }