<template>
    <li :class="{ 'light-blue lighten-5': isRouted }">
        <div :class="{ 'mx-n16 mx-md-n26 px-16 px-md-26 py-16 py-md-20': true }" ref="comment">
            <div class="mb-8">
                <strong class="font-size-16 font-weight-bold grey-2--text">{{ value?.user?.nickname }}</strong>
            </div>
            <div>
                <comment-input v-if="isEdit" v-model="value" usesCustomActivator buttonText="수정" @save="(item) => ($emit('save', item) || true) && (isEdit = !isEdit)" />
                <template v-else>
                    <b v-if="value?.mention" class="primary--text mr-4">{{ value.mention }}</b>
                    {{ value?.content }}
                </template>
            </div>

            <div class="mt-8">
                <v-row no-gutters align="center" class="font-size-14 grey-a--text">
                    <v-col cols="auto">{{ value?.createdAt?.toAgo?.() }}</v-col>
                    <template v-if="isLoggedIn">
                        <v-col cols="auto"><span class="mx-8">·</span></v-col>
                        <v-col cols="auto">
                            <v-btn @click="showsReply = !showsReply" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">답글달기</v-btn>
                        </v-col>
                    </template>
                    <template v-if="isUser">
                        <v-col cols="auto"><span class="mx-8">·</span></v-col>
                        <v-col cols="auto">
                            <v-btn @click="isEdit = !isEdit" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">수정</v-btn>
                        </v-col>
                        <v-col cols="auto"><span class="mx-8">·</span></v-col>
                        <v-col cols="auto">
                            <v-btn @click="$emit('remove', value)" x-small text color="grey-a" class="h-auto min-w-auto font-weight-regular v-btn--none-active-bg pa-0 mb-2">삭제</v-btn>
                        </v-col>
                    </template>
                </v-row>
            </div>
        </div>

        <v-divider />
        <v-expand-transition>
            <div v-if="showsReply" class="grey-f7 pa-10 pa-md-20">
                <comment-input v-if="isLoggedIn" ref="comment-input" relative @save="(item) => $emit('save', { ...item, _comment: value._id, callback: () => (showsReply = !showsReply) })" />
            </div>
        </v-expand-transition>

        <v-divider v-if="showsReply" />
        <ul class="grey-f7">
            <comment-reply v-for="item in value?.children || []" :key="item._id" :value="item" @mention="onMention" @save="(item) => $emit('save', item)" @remove="(item) => $emit('remove', item)" />
        </ul>
    </li>
</template>

<script>
import CommentInput from "./comment-input.vue";
import CommentReply from "./comment-reply.vue";
export default {
    components: {
        CommentInput,
        CommentReply,
    },
    props: {
        value: {
            user: { type: Object },
            content: { type: String, default: "" },
            children: { type: Array, default: () => [] },
        },
        expanded: { type: Boolean, default: false },
    },
    data() {
        return {
            showsReply: this.expanded,
            isEdit: false,
        };
    },
    computed: {
        isUser() {
            return this.value?.user?._id == this.$store?.state?.payload?._user;
        },
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        isRouted() {
            return this.$route.params._comment == this.value?._id;
        },
    },
    mounted() {
        if (this.isRouted) this.scrollToThis();
    },
    watch: {
        isRouted(value) {
            if (value) this.scrollToThis();
        },
    },
    methods: {
        onMention(comment) {
            this.showsReply = true;
            this.$nextTick(() => this.$refs["comment-input"]?.onMention?.(comment));
        },
        scrollToThis() {
            this.$nextTick(() => setTimeout(() => window.scrollTo({ top: this.$refs["comment"]?.getBoundingClientRect?.()?.top - 96, behavior: "smooth" }), 500));
        },
    },
};
</script>
