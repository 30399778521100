<template>
    <v-avatar size="48" :color="color" class="notification-item-avatar">
        <v-img :src="avatar">
            <template v-slot:placeholder>
                <v-icon size="20" dark>{{ icon }}</v-icon>
            </template>
        </v-img>
    </v-avatar>
</template>

<script>
import { NOTIFICATION_TYPES } from "@/assets/variables";
const { reviewComment } = NOTIFICATION_TYPES;

export default {
    props: ["notification"],
    computed: {
        icon() {
            const { type } = this.notification || {};

            switch (type) {
                case reviewComment.value:
                    return "mdi-clipboard-text";
                default:
                    return;
            }
        },
        color() {
            const { type } = this.notification || {};

            switch (type) {
                case reviewComment.value:
                    return "yellow darken-2";
                default:
                    return;
            }
        },
        avatar() {
            const { type } = this.notification || {};

            switch (type) {
                case reviewComment.value:
                    return this.notification?.review?.images?.[0]?.url;
                default:
                    return;
            }
        },
    },
};
</script>

<style scoped>
</style>