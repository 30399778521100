<template>
    <v-menu v-model="isOpen" bottom right offset-y transition="slide-x-reverse-transition" content-class="mt-40 rounded-lg shadow-primary notification-view-menu" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <div class="px-2 px-md-8">
                <v-badge dot color="red" overlap :value="unreads.length" offset-x="7" offset-y="7" class="mb-6">
                    <v-icon size="18" color="white" v-bind="attrs" v-on="on">mdi-bell-outline</v-icon>
                </v-badge>
            </div>
        </template>
        <v-card width="520px" elevation="0">
            <notification-view-nav v-model="tab" v-bind="{ notifications, unreads }" @search="search" />
            <v-divider />
            <!-- <notification-view-filter /> -->
            <notification-view-content v-model="isOpen" v-bind="{ tab, notifications, unreads }" @search="search" />
        </v-card>
    </v-menu>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import NotificationViewNav from "./notification-view-nav.vue";
// import NotificationViewFilter from "./notification-view-filter.vue";
import NotificationViewContent from "./notification-view-content.vue";

export default {
    components: {
        NotificationViewNav,
        // NotificationViewFilter,
        NotificationViewContent,
    },
    data() {
        return {
            tab: 2,
            isOpen: false,

            timer: null,
            searchAt: dayjs().add(5, "minutes"),

            notifications: [],
        };
    },
    computed: {
        unreads() {
            return this.notifications?.filter(({ isRead }) => isRead == false) || [];
        },
    },
    mounted() {
        this.init();
    },
    destroyed() {
        if (!!this.timer) clearTimeout(this.timer);
    },
    watch: {
        isOpen() {
            if (this.isOpen) this.search().then(this.setTab);
        },
    },
    methods: {
        init() {
            this.tab = 1;
            this.search();
            this.setTimer();
        },
        reset() {
            this.searchAt = dayjs().add(5, "minutes");
        },
        setTimer() {
            if (!!this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (!dayjs().isBefore(this.searchAt)) this.search();
                this.setTimer();
            }, 1000);
        },
        async search() {
            try {
                let { notifications } = await api.v1.eyes.notifications.gets();
                this.notifications = notifications;

                this.reset();
            } catch (error) {
                this.$handleError(error);
            }
        },
        setTab() {
            if (this.unreads || [].length > 0) this.tab = 2;
            else this.tab = 3;
        },
    },
};
</script>

<style scoped>
@media (max-width:1200px){
    .user-menu--moblie .v-icon.white--text{
        color:#222 !important;
    }
    .notification-view-menu{
        position: fixed;
        top: 30px !important;
        left:50% !important;
        -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
        z-index: 100 !important;
        max-width:calc(100% - 24px);
        will-change: auto;
    }
}
@media (max-width:1024px){
}
@media (max-width:768px){
}
@media (max-width:576px){
}
</style>