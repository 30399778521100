<template>
    <div>
        <div class="py-20 py-md-30">
            <v-row no-gutters align="baseline">
                <v-col cols="auto">
                    <div class="pr-12">
                        <v-avatar size="24" color="grey-1f" class="font-size-14 font-weight-medium line-height-1 white--text">{{ index }}</v-avatar>
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex flex-wrap align-center font-size-16 font-size-md-18 font-weight-extrabold mb-10 mb-md-16">
                        <span class="pr-8">{{ label }}</span>
                        <span v-if="required" class="pr-8" style="color: rgb(255, 0, 0);">*</span>
                        <span v-if="!!comment" class="font-size-14 font-weight-regular grey-a--text">{{ comment }}</span>
                    </div>
                    <div>
                        <slot />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider />
    </div>
</template>

<script>
export default {
    props: {
        index: { type: String, default: 0 },
        label: { type: String, default: "" },
        comment: { type: String, default: "" },
        required: { type: Boolean, default: false },
    },
};
</script>

<style></style>
