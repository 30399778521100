<template>
    <div class="main-image-banner">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(slide, index) in banner?.slides" :key="index">
                    <v-card :to="slide?.isOuterLink ? null : slide?.url" :href="slide?.isOuterLink ? slide?.url : null" :target="slide?.target" class="main-image-banner__img">
                        <img :src="slide?.image" alt="이미지" />
                    </v-card>
                </div>
            </div>
        </div>
        <div class="swiper-control">
            <div class="swiper-prev"><i class="icon icon-chevron-left"></i></div>
            <!-- <div class="swiper-pagination"></div> -->
            <div class="swiper-next"><i class="icon icon-chevron-right"></i></div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import api from "@/api";
export default {
    props: {
        // code: { type: String, default: "main-bottom" },
    },
    data() {
        return {
            swiper: undefined,
            banner: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // const { code } = this;
            // let { banner } = await api.v1.banners.get({ code });
            let { banner } = await api.v1.banners.get({ code: this.$attrs.code });
            this.banner = banner;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-image-banner .swiper-container", {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    navigation: {
                        nextEl: ".main-image-banner .swiper-next",
                        prevEl: ".main-image-banner .swiper-prev",
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 36,
                        },
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// main-image-banner swiper
.main-image-banner .swiper-container {
    max-width: var(--container);
}
.main-image-banner .swiper-control {
    display: none;
    max-width: var(--container-lg);
}
.main-image-banner .swiper-prev {
    left: 0;
}
.main-image-banner .swiper-next {
    right: 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-image-banner .swiper-control {
        display: block;
    }
}

// main-image-banner
.main-image-banner {
    position: relative;
}
.main-image-banner__img {
    display: block;
    line-height: 0;
    border-radius: 10px !important;
    box-shadow: none !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .main-image-banner__img {
        border-radius: 20px !important;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
