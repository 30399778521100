<template>
    <div v-show="subtitle" class="notification-item-subtitle">
        {{ subtitle }}
    </div>
</template>

<script>
import { NOTIFICATION_TYPES } from "@/assets/variables";
const { reviewComment } = NOTIFICATION_TYPES;

export default {
    props: ["notification"],
    computed: {
        type() {
            return this.notification?.type;
        },
        subtitle() {
            const { type, review } = this.notification || {};

            let type1 = [reviewComment.value];

            if (type1.includes(type)) return `안과후기 - "${review?.subject}"`;

            return;
        },
    },
};
</script>
<style scoped>
.notification-item-subtitle{
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.notification-item-subtitle b{
    font-style: initial;
    font-weight: 700;
}
</style>
