import { render, staticRenderFns } from "./comments.vue?vue&type=template&id=15a3222a&scoped=true"
import script from "./comments.vue?vue&type=script&lang=js"
export * from "./comments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a3222a",
  null
  
)

export default component.exports