var render = function render(){
  var _vm$review, _vm$review2, _vm$review3, _vm$review3$charges, _vm$review3$charges$t, _vm$review3$charges$t2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$review = _vm.review) !== null && _vm$review !== void 0 && _vm$review.doctor ? _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.opened || _vm.showsDetail,
      expression: "opened || showsDetail"
    }],
    staticClass: "mt-16 mt-md-20"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('b', {
    staticClass: "font-size-14 grey-2--text"
  }, [_vm._v("진료내역 및 가격")])]), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(((_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : _vm$review2.services) || [], function (item) {
    return _c('v-chip', {
      key: item,
      attrs: {
        "outlined": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(_vm.SERVICES[item].text))]);
  }), 1), _c('v-row', {
    staticClass: "my-4 mx-n4"
  }, [_vm._l(_vm.imgs, function (img, index) {
    return [_c('v-col', {
      key: img,
      staticClass: "pa-4",
      attrs: {
        "cols": "3",
        "md": "2",
        "lg": "1"
      }
    }, [_c('imgs-dialog', _vm._b({
      attrs: {
        "title": "영수증 이미지",
        "disabled": !_vm.isLoggedIn
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on,
            setIndex = _ref.setIndex;
          return [_c('v-img', _vm._g(_vm._b({
            staticClass: "v-sheet--outlined rounded",
            attrs: {
              "src": img,
              "aspect-ratio": 1 / 1
            },
            on: {
              "click": function ($event) {
                return setIndex(index);
              }
            }
          }, 'v-img', attrs, false), on))];
        }
      }], null, true)
    }, 'imgs-dialog', {
      imgs: _vm.imgs
    }, false))], 1)];
  })], 2), _c('div', {
    staticClass: "mt-16"
  }, [_c('v-card', {
    staticClass: "grey-f7",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    staticClass: "px-16 py-12 font-size-14",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("총 금액")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(((_vm$review3 = _vm.review) === null || _vm$review3 === void 0 ? void 0 : (_vm$review3$charges = _vm$review3.charges) === null || _vm$review3$charges === void 0 ? void 0 : (_vm$review3$charges$t = _vm$review3$charges.total) === null || _vm$review3$charges$t === void 0 ? void 0 : (_vm$review3$charges$t2 = _vm$review3$charges$t.format) === null || _vm$review3$charges$t2 === void 0 ? void 0 : _vm$review3$charges$t2.call(_vm$review3$charges$t)) || 0) + " 원")])], 1)], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }