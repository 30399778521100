import axios from "@/plugins/axios";

let url = "/api/console/band-banner-files";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post({ _bandBanner, index, fieldName }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_bandBanner) formData.append("_bandBanner", _bandBanner);
        if (index !== undefined) formData.append("index", index);
        if (fieldName !== undefined) formData.append("fieldName", fieldName);
        formData.append("file", file);
        return axios.post(`${url}`, formData, { headers }).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
