var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "py-20 py-md-30"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-12"
  }, [_c('v-avatar', {
    staticClass: "font-size-14 font-weight-medium line-height-1 white--text",
    attrs: {
      "size": "24",
      "color": "grey-1f"
    }
  }, [_vm._v(_vm._s(_vm.index))])], 1)]), _c('v-col', [_c('div', {
    staticClass: "d-flex flex-wrap align-center font-size-16 font-size-md-18 font-weight-extrabold mb-10 mb-md-16"
  }, [_c('span', {
    staticClass: "pr-8"
  }, [_vm._v(_vm._s(_vm.label))]), _vm.required ? _c('span', {
    staticClass: "pr-8",
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]) : _vm._e(), !!_vm.comment ? _c('span', {
    staticClass: "font-size-14 font-weight-regular grey-a--text"
  }, [_vm._v(_vm._s(_vm.comment))]) : _vm._e()]), _c('div', [_vm._t("default")], 2)])], 1)], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }