var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('v-container', [_c('h1', {
    staticClass: "header__logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("아이리뷰")])])], 1), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__gnb justify-center"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1), _c('div', {
    staticClass: "header__right"
  }, [_c('main-header-search'), _c('user-menu')], 1), _c('div', {
    staticClass: "user-menu--moblie"
  }, [_c('user-menu')], 1)])])], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hamburger-btn"
  }, [_c('span')]);

}]

export { render, staticRenderFns }