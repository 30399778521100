<template>
    <v-chip-group column>
        <v-chip v-if="index" color="transparent" text-color="primary" class="font-size-40 font-weight-black mx-n10">
            {{ index }}
        </v-chip>
        <v-chip v-if="review?.meta?.isBest" color="red" text-color="white" class="font-weight-medium">Best 리뷰</v-chip>
        <v-chip v-if="review?.meta?.hasReceipt" color="secondary" text-color="white" class="font-weight-medium">영수증 인증</v-chip>
    </v-chip-group>
</template>

<script>
export default {
    props: {
        review: { type: Object, default: () => ({}) },
        index: { type: [Number, String, Boolean], default:null },
    },
};
</script>

<style></style>
