<template>
    <dialog-window v-model="showsDialog" max-width="660" v-bind="{ title }" :disabled="disabled || imgs.length < 1">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, setIndex }" />
        </template>
        <div class="pa-20 pa-md-40">
            <div :class="`imgs-slide imgs-slide-${random}`">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="img in imgs" :key="img._id">
                            <a :href="img" target="_blank" rel="noopener noreferrer" class="imgs-slide__img rounded-lg hidden">
                                <v-img :src="img" :aspect-ratio="3 / 2" width="100%" class="grey-f7" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="swiper-control">
                    <div class="swiper-prev"><i class="icon icon-chevron-left"></i></div>
                    <!-- <div class="swiper-pagination"></div> -->
                    <div class="swiper-next"><i class="icon icon-chevron-right"></i></div>
                </div>
            </div>

            <div thumbsSlider :class="`imgs-slide-thumb imgs-slide-thumb-${random}`">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="img in imgs" :key="img._id">
                            <div class="imgs-slide-thumb__img rounded-lg hidden">
                                <v-img :src="img" :aspect-ratio="3 / 2" width="100%" class="grey-f7" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <v-row no-gutters align="center">
            <v-col cols="auto">
                <v-btn x-large fab text @click="goPrev" :disabled="disable_prev">
                    <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
            <v-col class="rounded-lg" style="overflow:hidden;">
                <v-tabs-items :value="selectedIndex">
                    <v-tab-item v-for="(img, index) in imgs" :key="img._id" :value="index">
                        <v-fade-transition>
                            <a v-show="index == selectedIndex" :href="img" target="_blank" rel="noopener noreferrer">
                                <v-img :src="img" :aspect-ratio="3 / 2" width="100%" class="grey lighten-3" />
                            </a>
                        </v-fade-transition>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-col cols="auto">
                <v-btn x-large fab text @click="goNext" :disabled="disable_next">
                    <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col cols="auto">
                <v-btn x-large fab text @click="selectedIndex -= 4" :disabled="selectedIndex < 4">
                    <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
            <v-col class="mx-n4">
                <v-tabs-items :value="Math.floor(selectedIndex / 4)">
                    <v-tab-item v-for="(n, i) in Math.ceil(imgs.length / 4)" :key="i" :value="i">
                        <v-row no-gutters>
                            <v-col v-for="(src, j) in imgs.slice(i * 4, i * 4 + 4)" :key="src" cols="3" class="pa-4">
                                <v-img :src="src" :aspect-ratio="3 / 2" class="rounded-lg" @click="selectedIndex = i * 4 + j" style="cursor:pointer;" @mouseenter="selectedIndex = i * 4 + j">
                                    <v-fade-transition>
                                        <v-overlay v-show="i * 4 + j !== selectedIndex" absolute color="white"></v-overlay>
                                    </v-fade-transition>
                                </v-img>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-col cols="auto">
                <v-btn x-large fab text @click="selectedIndex += 4" :disabled="selectedIndex + 1 > imgs.length - (imgs.length % 4) || imgs.length - selectedIndex < imgs.length % 4">
                    <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row> -->
    </dialog-window>
</template>

<script>
import Swiper from "swiper/bundle";
import DialogWindow from "./dialog-window.vue";
export default {
    components: {
        DialogWindow,
    },
    props: {
        imgs: { type: Array, default: () => [] },
        title: { type: String, default: "리뷰 이미지" },
        disabled: { type: Boolean, default: false },
    },
    data: () => ({
        random: Math.random().toFixed(10).slice(2, 12),
        swiper: undefined,
        showsDialog: false,
        showsCloseBtn: false,
        selectedIndex: 0,
        Math,
    }),
    computed: {
        disable_prev() {
            return this.selectedIndex < 1;
        },
        disable_next() {
            return this.selectedIndex >= this.imgs.length - 1;
        },
    },
    watch: {
        showsDialog() {
            this.$refs["nav"]?.$forceUpdate?.();
            this.$nextTick(() => this.$refs?.["nav"]?.setWidths?.());
            this.init();
        },
        selectedIndex() {
            if (this.selectedIndex >= this.imgs.length) this.selectedIndex = this.imgs.length - 1;
            if (this.selectedIndex < 0) this.selectedIndex = 0;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        setIndex(index) {
            this.selectedIndex = index;
        },
        goPrev() {
            if (this.selectedIndex > 0) this.selectedIndex -= 1;
        },
        goNext() {
            if (this.selectedIndex < this.imgs.length - 1) this.selectedIndex += 1;
        },
        async init() {
            if (this.showsDialog)
                this.$nextTick(() => {
                    var swiper = new Swiper(`.imgs-slide-thumb-${this.random} .swiper-container`, {
                        observer: true,
                        observeParents: true,
                        spaceBetween: 10,
                        slidesPerView: 3,
                        freeMode: true,
                        watchSlidesProgress: true,
                        breakpoints: {
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 16,
                            },
                        },
                    });

                    var swiper2 = new Swiper(`.imgs-slide-${this.random} .swiper-container`, {
                        observer: true,
                        observeParents: true,
                        spaceBetween: 16,
                        navigation: {
                            nextEl: `.imgs-slide-${this.random} .swiper-next`,
                            prevEl: `.imgs-slide-${this.random} .swiper-prev`,
                        },
                        thumbs: {
                            swiper: swiper,
                        },
                        initialSlide: this.selectedIndex || 0,
                    });
                });
        },
    },
};
</script>

<style scoped>
/* imgs-slide swiper */
.imgs-slide {
    position: relative;
}
.imgs-slide .swiper-control {
    display: none;
}
.imgs-slide .swiper-prev,
.imgs-slide .swiper-next {
    box-shadow: none !important;
}
.imgs-slide .swiper-prev {
    left: -14px;
}
.imgs-slide .swiper-next {
    right: -14px;
}
.imgs-slide .swiper-button-disabled {
    opacity: 0.4;
    pointer-events: none;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .imgs-slide,
    .imgs-slide-thumb {
        padding: 0 50px;
    }
    .imgs-slide .swiper-control {
        display: block;
    }
}
/* imgs-slide */
.imgs-slide__img {
    display: block;
    line-height: 0;
}

/* imgs-slide-thumb swiper */
.imgs-slide-thumb .swiper-slide {
    opacity: 0.6;
    cursor: pointer;
}
.imgs-slide-thumb .swiper-slide-thumb-active {
    opacity: 1;
}
/* imgs-slide-thumb */
.imgs-slide-thumb {
    margin-top: 14px;
}
</style>
