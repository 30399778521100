var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "bottom": "",
      "right": "",
      "offset-y": "",
      "transition": "slide-x-reverse-transition",
      "content-class": "mt-40 rounded-lg shadow-primary notification-view-menu",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', {
          staticClass: "px-2 px-md-8"
        }, [_c('v-badge', {
          staticClass: "mb-6",
          attrs: {
            "dot": "",
            "color": "red",
            "overlap": "",
            "value": _vm.unreads.length,
            "offset-x": "7",
            "offset-y": "7"
          }
        }, [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "size": "18",
            "color": "white"
          }
        }, 'v-icon', attrs, false), on), [_vm._v("mdi-bell-outline")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.isOpen,
      callback: function ($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "520px",
      "elevation": "0"
    }
  }, [_c('notification-view-nav', _vm._b({
    on: {
      "search": _vm.search
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, 'notification-view-nav', {
    notifications: _vm.notifications,
    unreads: _vm.unreads
  }, false)), _c('v-divider'), _c('notification-view-content', _vm._b({
    on: {
      "search": _vm.search
    },
    model: {
      value: _vm.isOpen,
      callback: function ($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, 'notification-view-content', {
    tab: _vm.tab,
    notifications: _vm.notifications,
    unreads: _vm.unreads
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }