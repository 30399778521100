var render = function render(){
  var _vm$summary;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18 grey-2--text"
  }, [_vm._v(" 댓글 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : _vm$summary.totalCount))])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('review-report-form', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "pa-0",
          attrs: {
            "text": "",
            "height": "21",
            "x-small": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "size": "21",
            "color": "grey-7"
          }
        }, [_vm._v("mdi-alarm-light")]), _c('span', {
          staticClass: "font-size-14 font-weight-regular grey-7--text ml-4"
        }, [_vm._v("신고하기")])], 1)];
      }
    }])
  }, 'review-report-form', {
    review: _vm.review
  }, false))], 1)], 1), _c('v-divider', {
    staticClass: "border-2 grey-2 mt-8 mt-md-14"
  }), _c('comments', {
    attrs: {
      "value": _vm.items
    },
    on: {
      "save": _vm.save,
      "remove": _vm.remove
    }
  }), _vm.isLoggedIn ? _c('div', {
    staticClass: "py-16"
  }, [_vm.isLoggedIn ? _c('comment-input', {
    attrs: {
      "relative": ""
    },
    on: {
      "save": _vm.save
    }
  }) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }