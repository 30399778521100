<template>
    <dialog-window v-model="showsDialog" title="후기 신고" :max-width="isLoggedIn ? 660 : 400" persistent>
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-if="isLoggedIn">
            <v-card-text class="px-30 py-0" style="max-height: calc(90vh - 50px - 80px); overflow-y: auto">
                <div class="table-style ">
                    <myinfo-modify-row label="신고유형" pointer>
                        <v-select v-model="form.type" :items="reportTypes" placeholder="신고유형을 선택해주세요" type="text" v-bind="inputAttrs" />
                    </myinfo-modify-row>
                    <myinfo-modify-row label="제목" pointer>
                        <v-text-field v-model="form.subject" type="text" v-bind="inputAttrs" />
                    </myinfo-modify-row>
                    <myinfo-modify-row label="내용" pointer>
                        <v-textarea v-model="form.content" type="text" v-bind="inputAttrs" auto-grow />
                    </myinfo-modify-row>
                </div>
            </v-card-text>
            <v-card-actions class="px-30 py-16 justify-center">
                <v-btn x-large rounded color="primary" class="w-100 mw-180px" @click="save">신고하기</v-btn>
            </v-card-actions>
        </template>
        <template v-else>
            <v-card-text class="px-30 py-30 font-size-24" style="max-height: calc(90vh - 50px - 80px); overflow-y: auto; text-align: center;">
                로그인이 필요한 메뉴입니다.
            </v-card-text>
            <v-card-actions class="px-30 pb-16 justify-center">
                <v-btn x-large rounded color="primary" class="w-100 mw-180px" to="/login">로그인하기</v-btn>
            </v-card-actions>
        </template>
    </dialog-window>
</template>

<script>
import FormRow from "@/components/dumb/form-row.vue";
import DialogWindow from "@/components/dumb/dialog-window.vue";
import MyinfoModifyRow from "../mypage/myinfo-modify-row.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

import api from "@/api";
import { REPORT_TYPES } from "@/assets/variables";
const reportTypes = Object.values(REPORT_TYPES);

const inputAttrs = {
    outlined: true,
    "hide-details": true,
    class: "w-100 mw-lg-500px",
};

export default {
    components: {
        FormRow,
        DialogWindow,
        MyinfoModifyRow,
        DaumPostcode,
    },
    props: {
        review: { type: Object, default: () => ({}) },
    },
    data: () => ({
        window,
        inputAttrs,
        reportTypes,
        showsDialog: false,
        ready: false,
        form: {
            code: "review-report",
            subject: null,
            content: null,
            meta: {
                _review: null,
                _location: null,
            },
        },
    }),
    computed: {
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    watch: {
        showsDialog() {
            this.init();
        },
    },
    methods: {
        init() {
            const { _id: _review, _location } = this.review || {};
            this.form = JSON.parse(
                JSON.stringify({
                    code: "review-report",
                    subject: null,
                    content: null,
                    meta: { _review, _location },
                })
            );
        },
        validate() {
            let isValid = undefined;
            try {
                const { code, subject, content, meta } = this.form || {};

                if (!this.isLoggedIn) throw new Error("로그인이 필요한 메뉴입니다.");

                if (code != "review-report" || !meta || !meta?._review || !meta?._location) throw new Error("잘못된 요청입니다.");

                if (!subject || (subject?.length || 0) < 10) throw Error("제목은 10자 이상입니다.");
                if (!content || (content?.length || 0) < 10) throw Error("내용은 10자 이상입니다.");

                isValid = true;
            } catch (error) {
                alert(error.message);
                isValid = false;
            } finally {
                return isValid;
            }
        },
        async save() {
            const isValid = this.validate();
            if (!isValid) return;

            const { form } = await api.v1.forms.post(this.form);
            alert("성공적으로 신고되었습니다.");
            this.showsDialog = false;
        },
    },
};
</script>

<style></style>
