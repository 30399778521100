<template>
    <ul class="comment-list">
        <!-- <li v-if="value.length == 0">댓글이 없습니다.</li> -->
        <template v-for="item in value">
            <comment :key="item._id" :value="item" @save="(item) => $emit('save', item)" :expanded="expanded" @remove="(item) => $emit('remove', item)" />
        </template>
    </ul>
</template>
<script>
import Comment from "./comment.vue";
export default {
    components: { Comment },
    props: {
        value: { type: Array, default: () => [] },
        expanded: { type: Boolean, default: false },
    },
};
</script>

<style scoped></style>
