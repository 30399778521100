<template>
    <v-container>
        <div class="main-search">
            <div class="search-primary search-primary--lg search-primary--gra">
                <div class="search-primary__form">
                    <main-search-field outlined prepend-inner-icon="mdi-magnify" placeholder="'안과이름' 또는 '수술방법' 검색" />
                </div>
            </div>
            <div class="mt-10 mt-md-20">
                <main-search-chips />
            </div>
        </div>
    </v-container>
</template>

<script>
import MainSearchChips from "./main-search-chips.vue";
import MainSearchField from "./main-search-field.vue";
export default {
    components: {
        MainSearchChips,
        MainSearchField,
    },
};
</script>

<style></style>
