var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-window', {
    attrs: {
      "title": "후기 신고",
      "max-width": _vm.isLoggedIn ? 660 : 400,
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.showsDialog,
      callback: function ($$v) {
        _vm.showsDialog = $$v;
      },
      expression: "showsDialog"
    }
  }, [_vm.isLoggedIn ? [_c('v-card-text', {
    staticClass: "px-30 py-0",
    staticStyle: {
      "max-height": "calc(90vh - 50px - 80px)",
      "overflow-y": "auto"
    }
  }, [_c('div', {
    staticClass: "table-style"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "신고유형",
      "pointer": ""
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.reportTypes,
      "placeholder": "신고유형을 선택해주세요",
      "type": "text"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "제목",
      "pointer": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "내용",
      "pointer": ""
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "type": "text",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', _vm.inputAttrs, false))], 1)], 1)]), _c('v-card-actions', {
    staticClass: "px-30 py-16 justify-center"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("신고하기")])], 1)] : [_c('v-card-text', {
    staticClass: "px-30 py-30 font-size-24",
    staticStyle: {
      "max-height": "calc(90vh - 50px - 80px)",
      "overflow-y": "auto",
      "text-align": "center"
    }
  }, [_vm._v(" 로그인이 필요한 메뉴입니다. ")]), _c('v-card-actions', {
    staticClass: "px-30 pb-16 justify-center"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "primary",
      "to": "/login"
    }
  }, [_vm._v("로그인하기")])], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }