<template>
    <v-autocomplete :search-input.sync="text" v-bind="{ ...$attrs, items, itemText, loading, menuProps }" hide-no-data hide-details @keydown.enter="onEnter">
        <template #item="{ item }">
            <main-search-field-item v-bind="{ item }" />
        </template>
    </v-autocomplete>
</template>

<script>
import api from "@/api";
import sidoGugun from "@/assets/data/sido-gugun.json";
import { SERVICES, TAG_TYPES } from "@/assets/variables";

import MainSearchFieldItem from "./main-search-field-item.vue";

const areas = sidoGugun.flatMap(({ name: sido, gugun }) => [{ text: sido, value: sido, type: "areas" }, ...gugun.map(({ name: gugun }) => ({ text: `${sido} ${gugun}`, value: gugun, sido, type: "areas" }))]);
const services = Object.values(SERVICES).map((service) => ({ ...service, type: "services" }));
const menuProps = { rounded: "b-xl", nudgeBottom: "-8", contentClass: "white" };

export default {
    components: {
        MainSearchFieldItem,
    },
    props: {
        value: Object, // location
    },
    data() {
        return {
            text: null,
            areas,
            services,
            tags: [],
            locations: [],
            menuProps,
            loading: false,
        };
    },
    computed: {
        items() {
            const items = [];
            items.push(...this.services.filter(({ text }) => text.includes(this.text)));
            items.push(...this.areas.filter(({ text }) => text.includes(this.text)));
            items.push(...this.tags.map(({ text, _id: value }) => ({ text, value, type: "tags" })));
            items.push(...this.locations.map(({ name: text, _id: value, address, tags }) => ({ text, value, address, tags, type: "locations" })));

            return items;
        },
        filter() {
            const searchValue = this.text?.replace(/[.*+?^${}()|[\]\\]/g, "");
            return { searchValue };
        },
    },
    watch: {
        text(value = "") {
            if (value.length >= 2) this.search();
        },
    },
    methods: {
        async search() {
            try {
                this.loading = true;

                const { tags } = await api.v1.eyes.tags.gets({ params: { ...this.filter, type: TAG_TYPES.custom.value } });
                this.tags = tags;

                const { locations } = await api.v1.eyes.locations.gets({ params: { ...this.filter, sort: "name", withTagSearch: true } });
                this.locations = locations;

                this.loading = false;
            } catch (error) {
                console.error(error);
                this.loading = false;
            }
        },

        itemText(item) {
            if (item.type == "tags") return `＃ ${item.text}`;
            if (item.type == "areas") return `＃ ${item.text} 안과`;
            if (item.type == "services") return `＃ ${item.text}`;
            if (item.type == "locations") return `${item.text} ${item.address} ${(item?.tags || []).map(({ tag }) => tag).join(" ")}`;
        },

        onEnter() {
            if (!this.text) this.$router.push("/search");
            else this.$router.push(`/search/${this.text}`);
        },
    },
};
</script>
