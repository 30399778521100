var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs-items', {
    staticClass: "notification-list",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('p', {
    staticClass: "notification-list__txt"
  }, [_vm._v("알림을 로드하고 있습니다.")])]), _c('v-tab-item', [_vm.unreads.length ? _vm._l(_vm.unreads, function (notification, i) {
    return _c('notification-item', {
      key: i,
      attrs: {
        "notification": notification
      },
      on: {
        "close": _vm.close,
        "search": _vm.search
      }
    });
  }) : _c('p', {
    staticClass: "notification-list__txt"
  }, [_vm._v("미확인 알림이 없습니다.")])], 2), _c('v-tab-item', [_vm.notifications.length ? _vm._l(_vm.notifications, function (notification, i) {
    return _c('notification-item', _vm._b({
      key: i,
      on: {
        "close": _vm.close,
        "search": _vm.search
      }
    }, 'notification-item', {
      notification
    }, false));
  }) : _c('p', {
    staticClass: "notification-list__txt"
  }, [_vm._v("알림이 없습니다.")])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }