<template>
    <ul class="board-list board-list--text">
        <li v-if="!items.length">
            <div class="board-list--text__tit">공지사항이 없습니다.</div>
        </li>
        <li v-for="{ _id, subject, createdAt } in items" :key="_id">
            <router-link :to="`/center/notice/${_id}`">
                <div class="board-list--text__tit">{{ subject }}</div>
                <div class="board-list--text__date">{{ createdAt.toDate() }}</div>
            </router-link>
        </li>
    </ul>
</template>

<script>
import api from "@/api";
export default {
    computed: {
        items() {
            return [...(this.fixed || []), ...(this.notifications || [])];
        },
    },
    data() {
        return {
            limit: 4,

            fixed: [],
            notifications: [],
        };
    },
    methods: {
        async search() {
            let { limit } = this;
            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: { limit },
                params: { isNotice: true, sites: "" },
            });
            this.fixed = fixed;

            limit -= fixed.length;
            if (limit) {
                var { notifications } = await api.v1.center.notifications.gets({
                    headers: { limit },
                    params: { isNotice: false, sites: "" },
                });
                this.notifications = notifications;
            }
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style scoped>
</style>