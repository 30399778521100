var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.title,
      expression: "title"
    }],
    staticClass: "notification-item-title"
  }, [_vm._v(_vm._s(_vm.title))]);

}
var staticRenderFns = []

export { render, staticRenderFns }