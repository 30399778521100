import { WORK_CLASSIFICATION_TYPES } from "./constants";
const { LIST, DESC } = WORK_CLASSIFICATION_TYPES;

export const initial_classifications = [
    {
        term: { ko: "양식 분류", en: "Classification Of Style" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "초기양식", isIt: false },
            { term: "앵포르멜", isIt: false },
            { term: "기하추상", isIt: false },
            { term: "서정추상", isIt: false },
            { term: "미니멀", isIt: false },
            { term: "기타", isIt: false },
        ],
    },
    {
        term: { ko: "주제 분류", en: "Classification of Theme" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "누드화", isIt: false },
            { term: "풍경화", isIt: false },
            { term: "정물화", isIt: false },
            { term: "꽃", isIt: false },
            { term: "해경", isIt: false },
            { term: "기타", isIt: false },
        ],
    },
    {
        term: { ko: "작품 상태", en: "Condition of Work" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "이상없음", isIt: true },
            { term: "균열", isIt: false },
            { term: "오염", isIt: false },
            { term: "박락", isIt: false },
            { term: "기타", isIt: false },
        ],
    },
    {
        term: { ko: "상태 등급", en: "Condition Degree" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "S급", isIt: false },
            { term: "A급", isIt: false },
            { term: "B급", isIt: false },
            { term: "C급", isIt: false },
            { term: "D급", isIt: false },
            { term: "E급", isIt: false },
        ],
    },
    {
        term: { ko: "싸인 유무", en: "Signed or Not" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "싸인있음", isIt: false },
            { term: "싸인없음", isIt: false },
            { term: "-", isIt: false },
            { term: "싸인위치", isIt: false },
            { term: "우측하단", isIt: false },
            { term: "뒷면", isIt: false },
        ],
    },
    {
        term: { ko: "진품 보증", en: "Certificate of Authenticity" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "보증서유", isIt: false },
            { term: "보증서무", isIt: false },
            { term: "-", isIt: false },
            { term: "발행기관", isIt: false },
            { term: "화랑협회", isIt: false },
        ],
    },
    {
        term: { ko: "작품 이력", en: "Provenance" },
        type: LIST.value,
        desc: null,
        list: [
            { term: "작가 소장", isIt: false },
            { term: "갤러리 라온 소장", isIt: false },
        ],
    },
    {
        type: DESC.value,
        term: { ko: "참고 문헌", en: "Literature" },
        desc: "국립현대미술관, 다시보는 박서보, 2023",
        list: [],
    },
];
