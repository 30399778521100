import auction from "./auction";
import bandBanners from "./band-banners";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import eyes from "./eyes";
import fairs from "./fairs";
import forms from "./forms";
import me from "./me";
import message from "./message";
import setting from "./setting";
import shop from "./shop";
import terms from "./terms";
import users from "./users";
import popups from "./popups";

export default {
    auction,
    bandBanners,
    banners,
    boards,
    center,
    eyes,
    fairs,
    forms,
    me,
    message,
    setting,
    shop,
    terms,
    users,
    popups,
};
