<template>
    <div class="main-wide-image-banner">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(slide, index) in banner?.slides" :key="index">
                    <v-card :to="slide?.isOuterLink ? null : slide?.url" :href="slide?.isOuterLink ? slide?.url : null" :target="slide?.target" class="main-wide-image-banner__img">
                        <img :src="slide?.image" alt="이미지" />
                    </v-card>
                </div>
            </div>
        </div>
        <!-- <div class="swiper-control">
            <div class="swiper-prev"><i class="icon icon-chevron-left"></i></div>
            <div class="swiper-pagination"></div>
            <div class="swiper-next"><i class="icon icon-chevron-right"></i></div>
        </div> -->
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import api from "@/api";
export default {
    props: {
        code: { type: String, default: "main-bottom" },
    },
    data() {
        return {
            swiper: undefined,
            banner: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { code } = this;
            let { banner } = await api.v1.banners.get({ code });
            this.banner = banner;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-wide-image-banner .swiper-container", {
                    // slidesPerView: 1,
                    // spaceBetween: 20,
                    autoHeight: true,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    // navigation: {
                    //     nextEl: ".main-wide-image-banner .swiper-next",
                    //     prevEl: ".main-wide-image-banner .swiper-prev",
                    // },
                    // breakpoints: {
                    //     576: {
                    //         slidesPerView: 2,
                    //     },
                    //     768: {
                    //         slidesPerView: 2,
                    //         spaceBetween: 36,
                    //     },
                    // },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// main-wide-image-banner swiper
.main-wide-image-banner .swiper-control {
    display: none;
}
.main-wide-image-banner .swiper-prev {
    left: 0;
}
.main-wide-image-banner .swiper-next {
    right: 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-wide-image-banner .swiper-control {
        display: block;
    }
}

// main-wide-image-banner
.main-wide-image-banner {
    position: relative;
}
.main-wide-image-banner__img{
    display: block;
    line-height: 0;
    border-radius: 0 !important;
    box-shadow: none !important;
}
</style>
