var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "mb-14 mb-md-20"
  }, [_c('ul', {
    staticClass: "footer-link"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "/terms"
    }
  }, [_vm._v("이용약관")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/medical"
    }
  }, [_vm._v("의료정보운영방침 ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/email"
    }
  }, [_vm._v("이메일주소수신거부 ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/privacy"
    }
  }, [_vm._v("개인정보처리방침")])])])]), _c('div', {
    staticClass: "mt-10"
  }, [_c('p', {
    staticClass: "copyright"
  }, [_vm._v("© 2022. EYE REVIEW Co. all rights reserved.")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticStyle: {
      "opacity": ".2"
    },
    attrs: {
      "width": "220",
      "src": "/images/logo.svg"
    }
  })], 1)], 1)], 1), _c('u-scroll-top')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }