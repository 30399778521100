<template>
    <div class="d-flex flex-column" style="flex-grow: 1">
        <div class="mb-8 mb-md-10 ellip">
            <strong class="font-size-18 font-size-md-24 grey-2--text">{{ review?.subject }}</strong>
        </div>
        <div style="position: relative; flex-grow: 1">
            <v-row v-if="!showsDetail" class="row--x-small" :class="{ 'content-area-before': true }" @click="onClick">
                <v-col :class="{ 'content-area-before-text': true, blurred: !isLoggedIn }">
                    <div v-html="content" />
                </v-col>
                <v-col cols="auto" v-if="!isBiggerImage && !!images?.length">
                    <imgs-dialog :imgs="images" :disabled="isBest || isLink || !isLoggedIn">
                        <template #activator="{ attrs, on }">
                            <v-img v-bind="attrs" v-on="on" :src="images?.[0]" :lazy-src="images?.[0]" eager class="rounded" width="90" :aspect-ratio="1 / 1" style="overflow: hidden; cursor: pointer" content-class="d-flex justify-end align-end">
                                <v-overlay slot="placeholder" absolute z-index="0" color="grey lighten-2">
                                    <v-icon x-large color="grey">mdi-image-off-outline</v-icon>
                                </v-overlay>

                                <v-card width="26" height="26" tile dark color="rgba(0,0,0,0.5)" class="d-flex align-center justify-center" style="border-radius: 10px 0px !important">
                                    <span class="font-size-12 font-weight-light">{{ images?.length }}</span>
                                </v-card>
                            </v-img>
                        </template>
                    </imgs-dialog>
                </v-col>
            </v-row>
            <v-btn v-if="!isLoggedIn" dark color="grey" absolute style="right: 50%; bottom: 0; transform: translateX(50%)">로그인 후 확인하기</v-btn>
        </div>
        <div :class="{ 'content-area-after': true, blurred: !isLoggedIn }" ref="content-box" :style="afterStyle">
            <div v-html="review?.content" style="white-space: pre-wrap;" />
            <div class="mt-20 overflow-hidden">
                <v-row class="row--x-small">
                    <v-col v-for="photo in review?.photos || []" :key="photo._id" cols="auto">
                        <v-img :src="photo.url" eager max-width="1146" width="auto" />
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import ImgsDialog from "@/components/dumb/imgs-dialog.vue";

export default {
    components: {
        ImgsDialog,
    },
    props: {
        review: { type: Object, default: () => ({}) },
        opened: { type: Boolean, default: false },
        isBest: { type: Boolean, default: false },
        isLink: { type: Boolean, default: false },
        isLoggedIn: { type: Boolean, default: false },
        showsDetail: { type: Boolean, default: false },
        isBiggerImage: { type: Boolean, default: false },
    },
    computed: {
        content() {
            return this.review?.content?.replace?.(/(<([^>]+)>)/gi, "")?.replace?.(/&nbsp;/gi, " ");
        },
        afterStyle() {
            const { scrollHeight } = this.$refs["content-box"] || {};

            if (this.opened || this.showsDetail) {
                if (scrollHeight) return `max-height:${scrollHeight}px;`;
                return "max-height:initial;";
            } else return ``;
        },
        className() {
            let className = "content-box";
            if (this.opened || this.showsDetail) className += " content-box--shows";
            return className;
        },
        images() {
            const { photos = [], images = [] } = this.review || {};
            if (photos.length) return photos.map(({ url }) => url);
            else return images;
        },
    },
    methods: {
        onClick() {
            this.$emit("showsDetail", true);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-area-before {
    cursor: pointer;

    flex-grow: 1;
    overflow-y: hidden;
    white-space: pre-line;
}
.content-area-before-text {
    position: relative;
    max-height: 134px;
    overflow-y: hidden;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 134px;
    //     background: linear-gradient(to bottom, transparent, white 67%);
    //     -webkit-transition: opacity ease-in-out 0.2s;
    //     -ms-transition: opacity ease-in-out 0.2s;
    //     transition: opacity ease-in-out 0.2s;
    // }
}

::v-deep .v-card--default__location + .v-card--default__content .content-box {
    max-height: 120px;
}

.content-area-after {
    max-height: 0px;
    overflow-y: hidden;
    -webkit-transition: max-height ease-in-out 0.2s;
    -ms-transition: max-height ease-in-out 0.2s;
    transition: max-height ease-in-out 0.2s;
}

::v-deep {
    .v-image__image--preload {
        filter: none !important;
    }
}
</style>
