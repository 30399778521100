<template>
    <v-btn text dark class="pa-0" x-small @click="toggleLike">
        <i :class="iconClassName"></i>
        <span :class="textClassName">{{ likesCount }}</span>
    </v-btn>
</template>

<script>
import api from "@/api";
import { mapActions, mapMutations } from "vuex";
export default {
    props: {
        review: { type: Object, default: () => ({}) },
        iconEmptyClass: { type: String, default: "icon-heart-outline" },
        iconFilledClass: { type: String, default: "icon-heart" },
        textEmptyClass: { type: String, default: "font-size-14 font-weight-regular ml-4" },
        textFilledClass: { type: String, default: "font-size-14 font-weight-regular ml-4" },
    },
    data: () => ({
        likesCount: 0,
    }),
    computed: {
        _review() {
            return this.review?._id;
        },
        likes() {
            return this.$store.state.likes;
        },
        like() {
            return (this.likes || []).find(({ _review }) => _review == this.review?._id);
        },
        iconClassName() {
            if (!this.like) return this.iconEmptyClass;
            if (!!this.like) return this.iconFilledClass;
        },
        textClassName() {
            if (!this.like) return this.textEmptyClass;
            if (!!this.like) return this.textFilledClass;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "review.meta.likes"() {
            this.init();
        },
    },
    methods: {
        ...mapActions(["getLikes"]),
        ...mapMutations(["setLikes"]),
        async init() {
            this.likesCount = this.review?.meta?.likes || 0;
            if (this.$store.state.payload?._user && !this.likes) await this.getLikes();
        },
        async toggleLike() {
            if (!this.$store.state.payload?._user) return;
            if (!!this.like) {
                await api.v1.me.likes.delete({ _id: this.like._id });
                this.setLikes({ likes: (this.likes || []).filter(({ _id }) => _id !== this.like._id) });
                this.likesCount -= 1;
            } else {
                const { _review } = this;
                const { like } = await api.v1.me.likes.post({ _review });
                this.setLikes({ likes: [...(this.likes || []), like] });
                this.likesCount += 1;
            }
        },
    },
};
</script>

<style></style>
