var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "xl",
      "elevation": "6"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-comment mr-12"
  }), _c('strong', {
    staticClass: "font-size-20 font-size-lg-24 grey-2--text"
  }, [_vm._v("안과후기")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('strong', {
    staticClass: "font-size-24 font-size-lg-44 line-height-1 primary--text"
  }, [_vm._v(_vm._s(_vm.counts.reviews))]), _c('span', {
    staticClass: "font-size-16 font-size-lg-20 grey-a0--text ml-4 ml-lg-12"
  }, [_vm._v("개")])])])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "xl",
      "elevation": "6"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-pharmacy mr-12"
  }), _c('strong', {
    staticClass: "font-size-20 font-size-lg-24 grey-2--text"
  }, [_vm._v("병원정보")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('strong', {
    staticClass: "font-size-24 font-size-lg-44 line-height-1 primary--text"
  }, [_vm._v(_vm._s(_vm.counts.locations))]), _c('span', {
    staticClass: "font-size-16 font-size-lg-20 grey-a0--text ml-4 ml-lg-12"
  }, [_vm._v("건")])])])], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }