var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !!_vm.reviews,
      expression: "!loading && !!reviews"
    }],
    staticClass: "review-list-best"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.reviews, function (review, index) {
    return _c('div', {
      key: review._id,
      staticClass: "swiper-slide"
    }, [_c('review-item', _vm._b({
      attrs: {
        "isBest": ""
      }
    }, 'review-item', {
      review,
      index: index + 1,
      isBiggerImage: _vm.isBiggerImage
    }, false))], 1);
  }), 0)]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left-white icon-chevron--lg"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right-white icon-chevron--lg"
  })])]);

}]

export { render, staticRenderFns }