export const QuestionRoutes = [
    {
        path: `/`,
        components: {
            question: () => import(/* webpackChunkName: "question" */ "../components/client/center/question/question-list.vue")
        },
        props: {
            question: { viewType: "list" }
        }
    },
    {
        path: `create`,
        components: {
            question: () => import(/* webpackChunkName: "question" */ "../components/client/center/question/question-input.vue")
        },
        props: {
            question: { viewType: "input" }
        }
    },
    {
        path: `:_question`,
        components: {
            question: () => import(/* webpackChunkName: "question" */ "../components/client/center/question/question-view.vue")
        },
        props: {
            question: route => ({ viewType: "view", _id: route.params._question })
        }
    },
];