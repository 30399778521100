export const getCurrentPosition = () => {
    const { geolocation } = navigator;

    return new Promise((resolve, reject) => {
        if (geolocation)
            geolocation.getCurrentPosition(
                // if success
                (position) => resolve(position),

                // if fail
                (error) => {
                    console.error(error);

                    if (error.code === 1) return reject("위치 권한을 허용하신 뒤 다시 시도하세요");
                    else return reject(error.message);
                }
            );
        else return reject("이 브라우저는 GPS를 지원하지 않습니다");
    });
};
