import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },
    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login/sns-validate",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/sns-validate.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/JoinPage.vue"),
        props: true,
    },
    {
        // 회원가입 완료
        path: "/join/complete",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
        props: true,
    },
    {
        // 마이페이지 - 댓글 관리
        path: "/mypage/comments",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/CommentListPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 리뷰 관리
        path: "/mypage/reviews",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ReviewListPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 게시글 관리
        path: "/mypage/boards",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BoardListPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 1:1 문의
        path: "/mypage/question",
        component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
        children: [...QuestionRoutes],
        props: true,
    },
    {
        // 마이페이지 - 회원정보 수정 (상세)
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 마이페이지 -회원정보 수정 (수정)
        path: "/mypage/myinfo/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 서비스이용약관
        path: "/terms",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/TermsPage.vue"),
        props: true,
    },
    {
        // 의료정보운영방침
        path: "/medical",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/MedicalPolicyPage.vue"),
        props: true,
    },
    {
        // 이메일주소수신거부
        path: "/email",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/EmailPolicyPage.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 안과찾기 (지도)
        path: "/map",
        component: () => import(/* webpackChunkName: "map" */ "../pages/client/map/MapPage.vue"),
        props: true,
    },
    {
        // 안과찾기 (지도)
        path: "/map/:mode",
        component: () => import(/* webpackChunkName: "map" */ "../pages/client/map/MapPage.vue"),
        props: true,
    },
    {
        // 안과찾기 (지도)
        path: "/map/:mode/:keyword",
        component: () => import(/* webpackChunkName: "map" */ "../pages/client/map/MapPage.vue"),
        props: true,
    },
    {
        // 혼자가편해서 - 검색
        path: "/search",
        component: () => import(/* webpackChunkName: "honja-search" */ "../pages/client/search/SearchPage.vue"),
        props: true,
    },
    {
        // 혼자가편해서 - 검색
        path: "/search/:searchValue",
        component: () => import(/* webpackChunkName: "honja-search" */ "../pages/client/search/SearchPage.vue"),
        props: true,
    },
    {
        // 혼자가편해서 - 검색
        path: "/search/:searchValue/:mode",
        component: () => import(/* webpackChunkName: "honja-search" */ "../pages/client/search/SearchPage.vue"),
        props: true,
    },
    {
        // 안과찾기 (목록)
        path: "/locations",
        component: () => import(/* webpackChunkName: "locations" */ "../pages/client/locations/LocationListPage.vue"),
        props: true,
    },
    {
        // 안과찾기 (목록) - 상세
        path: "/locations/:_location",
        component: () => import(/* webpackChunkName: "locations" */ "../pages/client/locations/LocationViewPage.vue"),
        props: true,
    },
    {
        // 안과후기 (목록)
        path: "/reviews",
        component: () => import(/* webpackChunkName: "reviews" */ "../pages/client/reviews/ReviewListPage.vue"),
        props: true,
    },
    {
        // 후기쓰기
        path: "/reviews/write",
        component: () => import(/* webpackChunkName: "reviews" */ "../pages/client/reviews/ReviewWritePage.vue"),
        props: true,
    },
    {
        // 안과후기 (목록) - 상세
        path: "/reviews/:_review",
        component: () => import(/* webpackChunkName: "reviews" */ "../pages/client/reviews/ReviewItemPage.vue"),
        props: true,
    },
    {
        // 안과후기 (목록) - 상세 - 코멘트
        path: "/reviews/:_review/comments",
        component: () => import(/* webpackChunkName: "reviews" */ "../pages/client/reviews/ReviewItemPage.vue"),
        props: true,
    },
    {
        // 안과후기 (목록) - 상세 - 코멘트
        path: "/reviews/:_review/comments/:_comment",
        component: () => import(/* webpackChunkName: "reviews" */ "../pages/client/reviews/ReviewItemPage.vue"),
        props: true,
    },
    {
        // 커뮤니티 (게시판)
        path: "/community/:code",
        component: () => import(/* webpackChunkName: "boards" */ "../pages/client/community/CommunityPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 고객센터 - FAQ
        path: "/center/faq",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
        props: true,
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 안과 목록
        path: "/console/eyes/locations",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/eyes/LocationListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 리뷰 목록
        path: "/console/eyes/reviews",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/eyes/ReviewListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인 관리
        path: "/console/eyes/main-setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/eyes/MainSettingPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인 관리
        path: "/console/eyes/main-setting/:tab",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/eyes/MainSettingPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 띠배너관리
        path: "/console/band-banners",
        name: "console-band-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/band-banners/BandBannerListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업관리
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf("console")) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
