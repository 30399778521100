<template>
    <v-fade-transition>
        <div class="header-search" v-show="shows">
            <div class="header-search__form">
                <v-text-field v-model="keyword" outlined hide-details prepend-inner-icon="mdi-magnify" placeholder="'안과이름' 또는 '수술방법' 검색" @keydown.enter.prevent.stop.capture="onEnter" enterkeyhint="enter" @keyup.enter="gnbMobileMenu()" />
            </div>
        </div>
    </v-fade-transition>
</template>

<script>
export default {
    data: () => ({
        keyword: null,
    }),
    computed: {
        shows() {
            // if (this.$route.path == "/") return false;
            // if (/^\/map/.test(this.$route.path)) return false;
            // if (/^\/search/.test(this.$route.path)) return false;
            // if (/^\/mypage\/myinfo/.test(this.$route.path)) return false;
            // if (/^\/reviews\/write/.test(this.$route.path)) return false;
            return true;
        },
    },
    methods: {
        onEnter() {
            if (!this.keyword) this.$router.push("/search");
            else this.$router.push(`/search/${this.keyword}`);
        },
        gnbMobileMenu() {
            var HTML = document.querySelector("html");
            var header = document.querySelector(".header");

            header.classList.remove("mobile-menu-on");
            HTML.classList.remove("hidden");
        },
    },
};
</script>

<style></style>
