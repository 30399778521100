import axios from "@/plugins/axios";

let url = "/api/v1/eyes/files";

export default {
    post({ _review, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_review) formData.append("_review", _review);
        if (index !== undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(`${url}`, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
