import files from "./files";
import locations from "./locations";
import map from "./map";
import notifications from "./notifications";
import reviews from "./reviews";
import tags from "./tags";

export default {
    files,
    locations,
    map,
    notifications,
    reviews,
    tags,
};
