<template>
    <div>
        <v-bottom-sheet v-if="isMobile" v-model="shows">
            <template v-slot:activator="{ attrs, on }">
                <slot name="activator" v-bind="{ attrs, on }" />
                <div v-if="!usesCustomActivator" v-bind="attrs" v-on="on" :class="`comment-input-wrap${relative ? ' relative' : ''}`">
                    <div class="comment-input" style="pointer-events: none">
                        <v-text-field v-model="content" placeholder="댓글을 입력해주세요." outlined hide-details class="comment-input__input">
                            <v-btn text small color="primary" slot="append" class="v-btn--effect-none comment-input__btn">{{ buttonText }}</v-btn>
                        </v-text-field>
                    </div>
                </div>
            </template>
            <div class="comment-input-bottom-sheet">
                <div class="comment-input">
                    <v-row align="center" class="row--x-small">
                        <v-col>
                            <v-text-field ref="text-field" v-model="content" placeholder="댓글을 입력해주세요." outlined hide-details class="comment-input__input" :prefix="mention" @keydown.delete="removeMentionTo" @keydown.enter="emit">
                                <v-btn text small color="primary" slot="append" class="comment-input__btn" @click="emit">{{ buttonText }}</v-btn>
                            </v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-icon @click="close">mdi-close-circle</v-icon>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-bottom-sheet>

        <v-text-field v-else ref="text-field" v-model="content" placeholder="댓글을 입력해주세요." outlined hide-details class="comment-input__input" :prefix="mention" @keydown.delete="removeMentionTo" @keydown.enter="emit">
            <v-btn text small color="primary" slot="append" class="comment-input__btn" @click="emit">{{ buttonText }}</v-btn>
        </v-text-field>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        isMobile: { type: Boolean, default: false },
        relative: { type: Boolean, default: false },
        buttonText: { type: String, default: "등록" },
        usesCustomActivator: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
        content: "",
        mention_comment: null,
    }),
    computed: {
        comment() {
            let { content, mention, callback } = this;
            return { ...this.value, content, callback, mention };
        },
        mention() {
            if (this.mention_comment) {
                if (typeof this.mention_comment == "object") return `@${this.mention_comment?.writer?.nickname}`;
                if (typeof this.mention_comment == "string") return this.mention_comment;
                return undefined;
            } else return undefined;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "value.content"() {
            this.init();
        },
    },
    methods: {
        init() {
            this.content = this.value?.content || "";
            this.mention_comment = this.value?.mention;
        },
        emit() {
            this.$emit("save", this.comment);
        },
        onMention(comment) {
            this.mention_comment = comment;
            this.shows = true;
        },
        removeMentionTo() {
            if (this.content?.length == 0) this.mention_comment = null;
        },
        close() {
            this.shows = false;
        },
        callback() {
            this.init();
            this.close();
        },
    },
};
</script>

<style scoped>
.comment-input-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.comment-input-wrap.relative {
    position: relative;
    bottom: auto;
    left: auto;
}
.comment-input-wrap > .comment-input {
    border-top: none;
    padding: 0;
}
.comment-input-bottom-sheet {
    height: 100vh;
    background-color: #f5f5f5;
}
.comment-input-bottom-sheet .comment-input {
    border-bottom: 1px solid var(--border-color);
}
.comment-input {
    width: 100%;
    padding: 16px;
    border-top: 1px solid var(--border-color);
}
>>> .comment-input__input.v-text-field:not(.v-textarea) .v-input__slot,
.comment-input__btn {
    height: var(--input-height) !important;
}
>>> .comment-input__input .v-input__control {
    background-color: #fff;
}
>>> .comment-input__input .v-text-field__prefix {
    color: var(--v-primary-base);
    padding-right: 10px;
}
.comment-input__btn {
    margin-right: -12px;
    padding: 0 16px !important;
}
</style>
