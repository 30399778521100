<template>
    <div>
        <!-- S: CONTENT -->
        <v-main id="main" v-if="!isMap">
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <slot name="map" />
        <!-- E: CONTENT -->
        <slot name="drawer" />

        <!-- S: FOOTER -->
        <main-footer v-if="!hideFooter"></main-footer>
        <!-- E: FOOTER -->
    </div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
export default {
    props: {
        isMap: { type: Boolean, default: false },
        isMain: { type: Boolean, default: true },
        hideFooter: { type: Boolean, default: false },
    },
    components: {
        MainHeader,
        MainFooter,
    },
};
</script>
