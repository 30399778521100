<template>
    <div class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in banner?.slides" :key="index" class="swiper-slide">
                    <v-card :to="slide?.isOuterLink ? null : slide?.url" :href="slide?.isOuterLink ? slide?.url : null" :target="slide?.target" elevation="0" class="main-visual__inner">
                        <div class="main-visual__img" :style="'background-image:url(' + slide?.image + ');'">
                            <span class="d-sr-only">{{ slide?.meta?.title }}</span>
                        </div>
                    </v-card>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-chevron-left"></i></div>
                <!-- <div class="swiper-pagination"></div> -->
                <div class="swiper-next"><i class="icon icon-chevron-right"></i></div>
            </div>
        </div>
    </div>
    <!-- <div class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in banner.slides" :key="index" class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" :style="'background-image:url('+ slide?.image +');'"></div>
                        <div class="main-visual__con">
                            <h3 class="main-visual__tit">{{ slide?.meta?.title }}</h3>
                            <div class="main-visual__info">
                                <ul>
                                    <li>{{ slide?.meta?.location }}</li>
                                    <li>{{ slide?.meta?.date?.toDate() }}</li>
                                </ul>
                            </div>
                            <v-btn v-if="slide?.url" :to="slide?.isOuterLink ? undefined : slide?.url" :href="slide?.isOuterLink ? slide?.url : undefined" small text color="white" class="main-visual__btn pa-4 ml-n4">VIEW MORE <i class="icon icon-bar white mt-2 ml-6"></i></v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-arrow-left"></i></div>
                <div class="swiper-pagination"></div>
                <div class="swiper-next"><i class="icon icon-arrow-right"></i></div>
            </div>
        </div>
    </div> -->
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import detect from "@/plugins/detect";
export default {
    data() {
        return {
            detect,
            banner: null,
            swiper: undefined,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banner } = await api.v1.banners.get({ code: this.$attrs.code });
            this.banner = banner;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    // pagination: {
                    //     el: ".main-visual .swiper-pagination",
                    //     type: "custom",
                    //     renderCustom: function(swiper, current, total) {
                    //         function numberAppend(n) {
                    //             return n < 10 ? "0" + n.toString() : n.toString();
                    //         }
                    //         return '<span class="swiper-pagination-current">' + numberAppend(current) + '</span> / <span class="swiper-pagination-total">' + numberAppend(total) + "</span>";
                    //     },
                    // },
                    navigation: {
                        nextEl: ".main-visual .swiper-next",
                        prevEl: ".main-visual .swiper-prev",
                    },
                });
            });
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
}
.main-visual .swiper-control {
    display: none;
    max-width: var(--container-lg);
}
.main-visual__inner {
    display: block;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    border-radius: 0 !important;
    margin: auto;
}
.main-visual__img {
    max-width: 100%;
    width: 100%;
    padding-top: calc(300/600 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual__img {
        padding-top: calc(300/1920 * 100%);
    }
}
@media (min-width: 1024px) {
    .main-visual .swiper-control {
        display: block;
    }
}
</style>
