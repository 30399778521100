var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    staticClass: "notification-item-avatar",
    attrs: {
      "size": "48",
      "color": _vm.color
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.avatar
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "size": "20",
            "dark": ""
          }
        }, [_vm._v(_vm._s(_vm.icon))])];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }