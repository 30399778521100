<template>
    <div v-show="title" class="notification-item-title">{{ title }}</div>
</template>

<script>
import { NOTIFICATION_TYPES } from "@/assets/variables";
const { reviewComment } = NOTIFICATION_TYPES;

export default {
    props: ["notification"],
    computed: {
        title() {
            const { type, review } = this.notification || {};

            let type1 = [reviewComment.value];

            if (type1.includes(type)) return `댓글 알림`;

            return;
        },
    },
};
</script>

<style scoped>
.notification-item-title{
    font-size: 1.4rem;
    font-weight:700;
    color: var(--v-primary-base);
    margin-bottom: 4px;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
</style>
