var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-window', _vm._b({
    attrs: {
      "max-width": "660",
      "disabled": _vm.disabled || _vm.imgs.length < 1
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          setIndex: _vm.setIndex
        })];
      }
    }], null, true),
    model: {
      value: _vm.showsDialog,
      callback: function ($$v) {
        _vm.showsDialog = $$v;
      },
      expression: "showsDialog"
    }
  }, 'dialog-window', {
    title: _vm.title
  }, false), [_c('div', {
    staticClass: "pa-20 pa-md-40"
  }, [_c('div', {
    class: `imgs-slide imgs-slide-${_vm.random}`
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.imgs, function (img) {
    return _c('div', {
      key: img._id,
      staticClass: "swiper-slide"
    }, [_c('a', {
      staticClass: "imgs-slide__img rounded-lg hidden",
      attrs: {
        "href": img,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_c('v-img', {
      staticClass: "grey-f7",
      attrs: {
        "src": img,
        "aspect-ratio": 3 / 2,
        "width": "100%"
      }
    })], 1)]);
  }), 0)]), _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-chevron-left"
  })]), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-chevron-right"
  })])])]), _c('div', {
    class: `imgs-slide-thumb imgs-slide-thumb-${_vm.random}`,
    attrs: {
      "thumbsSlider": ""
    }
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.imgs, function (img) {
    return _c('div', {
      key: img._id,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "imgs-slide-thumb__img rounded-lg hidden"
    }, [_c('v-img', {
      staticClass: "grey-f7",
      attrs: {
        "src": img,
        "aspect-ratio": 3 / 2,
        "width": "100%"
      }
    })], 1)]);
  }), 0)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }