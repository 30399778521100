export const AUCTION_PROGRAM_HALLS = {
    AUCTION_A: { value: "A", text: "A-auction" },
    AUCTION_B: { value: "B", text: "B-auction" },
};

export const WORK_CLASSIFICATION_TYPES = {
    NULL: { text: "없음", value: "work_classification_type:NULL" },
    DESC: { text: "텍스트", value: "work_classification_type:DESC" },
    LIST: { text: "리스트", value: "work_classification_type:LIST" },
};

export const WORK_AUCTION_MODES = {
    NULL: { text: "없음", value: "work_classification_type:NULL" },
    PRIVATE: { text: "프라이빗", value: "work_classification_type:PRIVATE" },
    PROGRAM: { text: "프로그램", value: "work_classification_type:PROGRAM" },
};

export const USER_TYPES = {
    PERSON: { text: "일반회원", value: "PERSON" },
    COMPANY: { text: "기업회원", value: "COMPANY" },
};

export const USER_NOTIFY_METHODS = {
    SMS: { text: "SMS(문자메시지)", value: "user.notification:SMS" },
    EMAIL: { text: "이메일", value: "user.notification:EMAIL" },
    PHONE: { text: "전화", value: "user.notification:PHONE" },
};

const man = 10000;
export const BID_UNIT_POLICIES = [
    { value: 5 * man, test: (value) => value < 100 * man, text: "100만원 미만" },
    { value: 10 * man, test: (value) => value < 300 * man, text: "100만원 이상 ~ 300만원 미만" },
    { value: 20 * man, test: (value) => value < 500 * man, text: "300만원 이상 ~ 500만원 미만" },
    { value: 30 * man, test: (value) => value < 1000 * man, text: "500만원 이상 ~ 1,000만원 미만" },
    { value: 50 * man, test: (value) => value < 3000 * man, text: "1,000만원 이상 ~ 3,000만원 미만" },
    { value: 100 * man, test: (value) => value < 5000 * man, text: "3,000만원 이상 ~ 5,000만원 미만" },
    { value: 200 * man, test: (value) => value < 10000 * man, text: "5,000만원 이상 ~ 1억원 미만" },
    { value: 300 * man, test: (value) => value < 20000 * man, text: "1억원 이상 ~ 2억원 미만" },
    { value: 500 * man, test: (value) => value < 50000 * man, text: "2억원 이상 ~ 5억원 미만" },
    { value: 1000 * man, test: (value) => value >= 50000 * man, text: "5억원 이상" },
];

export const DELIVERY_POLICIES_DOMESTIC = [
    { value: 50000, text: "서울", test: (value) => [/^서울/].some((item) => item.test(value)) },
    { value: 100000, text: "수도권", test: (value) => [/^경기/, /^인천/].some((item) => item.test(value)) },
    { value: 150000, text: "그 외 국내지역", test: () => true },
];

export const PRIVATE_SALES_TABS = {
    LIST: { value: "LIST", text: "작품목록" },
    ABOUT: { value: "ABOUT", text: "Private Sales이란?" },
    MERIT: { value: "MERIT", text: "Private Sales의 좋은점" },
    KATA: { value: "KATA", text: "KATA 옥션의 강점" },
    GENRE: { value: "GENRE", text: "장르와 전제조건" },
};

export const HOW_TO_BID_TABS = {
    JOIN: { value: "JOIN", text: "회원가입" },
    PREVIEW: { value: "PREVIEW", text: "프리뷰" },
    ONLINE: { value: "ONLINE", text: "온라인경매" },
    LIVE: { value: "LIVE", text: "라이브경매" },
    NOTICE: { value: "NOTICE", text: "유의사항" },
    SUCCESS: { value: "SUCCESS", text: "낙찰 및 수수료" },
    DELIVERY: { value: "DELIVERY", text: "픽업 및 배송" },
};

export const SERVICES = {
    smile: { text: "스마일라식", value: "smile" },
    lasik: { text: "라식", value: "lasik" },
    lasek: { text: "라섹", value: "lasek" },
    cataractSenilis: { text: "노안백내장", value: "cataractSenilis" },
    lensImplantation: { text: "안내렌즈삽입술", value: "lensImplantation" },
    rentinitis: { text: "망막", value: "rentinitis" },
    glaucoma: { text: "녹내장", value: "glaucoma" },
    dryeyes: { text: "안구건조증", value: "dryeyes" },
    etc: { text: "기타", value: "etc" },
};

export const CRITERIA = {
    HOSPITALITY: { value: "hospitality", text: "친절도", desc: "접수 수납 과정 등 고객 응대" },
    DESCRIPTION: { value: "description", text: "진료설명", desc: "증상과 치료 및 비용에 대한 설명" },
    TREATMENTS: { value: "treatments", text: "진료만족도", desc: "진료 후 문제의 개선도" },
    FACILITIES: { value: "facilities", text: "시설장비", desc: "진료실과 병원의 위생도 및 장비 수준" },
    CHARGES: { value: "charges", text: "가격", desc: "진료비 수준" },
};

export const TAG_TYPES = {
    address: { text: "지역", value: "address" },
    location: { text: "장소", value: "location" },
    service: { text: "진료과목", value: "service" },
    custom: { text: "커스텀", value: "custom" },
};

export const NOTIFICATION_TYPES = {
    reviewComment: { text: "후기댓글알림", value: "reviewComment" },
};

export const REPORT_TYPES = {
    fabrication: { value: "fabrication", text: "허위과대 정보" },
    advertContent: { value: "advertContent", text: "광고성 정보" },
    defamingContent: { value: "defamingContent", text: "명예훼손" },
    hamfulContent: { value: "hamfulContent", text: "유해성 정보" },
    other: { value: "other", text: "기타" },
};
