var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small user-menu",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm.accessToken ? _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('notification-view')], 1) : _vm._e(), _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('gnb-login-button')], 1), _vm.accessToken ? _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-14 px-2 px-md-8",
    attrs: {
      "to": "/mypage/myinfo"
    }
  }, [_c('span', [_vm._v("마이페이지")])])], 1) : _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-14 px-2 px-md-8",
    attrs: {
      "to": "/join"
    }
  }, [_c('span', [_vm._v("회원가입")])])], 1), _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-14 px-2 px-md-8",
    attrs: {
      "to": "/center/notice"
    }
  }, [_c('span', [_vm._v("고객센터")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }