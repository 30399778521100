var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.opened || _vm.showsDetail,
      expression: "opened || showsDetail"
    }],
    staticClass: "mw-400px mt-16 mt-md-20"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.criteria, function (criterion) {
    var _vm$review, _vm$review$stars;
    return _c('v-col', {
      key: criterion.value,
      attrs: {
        "cols": "6",
        "align": "center"
      }
    }, [_c('v-row', {
      staticClass: "mt-6 mt-sm-4",
      attrs: {
        "no-gutters": "",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "font-size-14 text-left",
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-80px"
    }, [criterion.bold ? _c('b', {
      staticClass: "grey-2--text"
    }, [_vm._v(_vm._s(criterion.text))]) : _c('span', {
      staticClass: "grey-7--text"
    }, [_vm._v(_vm._s(criterion.text))])])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-rating', {
      attrs: {
        "value": (_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$stars = _vm$review.stars) === null || _vm$review$stars === void 0 ? void 0 : _vm$review$stars[criterion.value],
        "readonly": "",
        "half-increments": "",
        "dense": "",
        "color": "warning",
        "background-color": "warning",
        "size": "16"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }