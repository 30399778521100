var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', _vm._b({
    staticClass: "justify-center",
    attrs: {
      "column": ""
    }
  }, 'v-chip-group', _vm.$attrs, false), _vm._l(_vm.items, function (item) {
    return _c('v-chip', {
      key: item._id,
      attrs: {
        "large": "",
        "outlined": "",
        "color": "grey-2",
        "to": _vm.to(item)
      }
    }, [_vm._v(_vm._s(item.tag))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }